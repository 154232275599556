import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["aria-labelledby"]
const _hoisted_2 = { class: "flex flex-col overflow-y-auto" }
const _hoisted_3 = ["id"]
const _hoisted_4 = {
  key: 2,
  class: "flex justify-end px-4 pb-4"
}

import { ref, onMounted, onBeforeUnmount } from "vue";
import CloseButton from "../CloseButton/CloseButton.vue";
import SharedButton from "../SharedButton.vue";
import { animationDurationMs } from "../../../services/services.modal";


export default /*@__PURE__*/_defineComponent({
  __name: 'SharedDialog',
  props: {
    closeActionButton: { type: Boolean },
    closeActionButtonText: { default: "Ok" },
    fullMaxHeight: { type: Boolean },
    headline: {},
    narrow: { type: Boolean },
    noBodyPadding: { type: Boolean },
    noScrollbar: { type: Boolean },
    preventDismiss: { type: Boolean },
    wide: { type: Boolean }
  },
  emits: ["closing", "closed"],
  setup(__props: any, { expose: __expose, emit: __emit }) {


const emit = __emit;

const uuid = (Math.random() + 1).toString(36).substring(2);

const slideIn = ref(false);
const dialogEl = ref<HTMLDialogElement | null>(null);
const open = () => {
	slideIn.value = true;
	dialogEl.value?.showModal();
	dialogEl.value?.focus();
};
const close = (triggeredBy = "") => {
	slideIn.value = false;
	emit("closing", triggeredBy);
};

const onAnimationEnd = ({ animationName }: AnimationEvent) => {
	if (animationName !== "dialogSlideOut") return;

	dialogEl.value?.close();
	emit("closed");
};
onMounted(() => {
	dialogEl.value?.addEventListener("animationend", onAnimationEnd);
	dialogEl.value?.addEventListener("animationcancel", onAnimationEnd);
});
onBeforeUnmount(() => {
	dialogEl.value?.removeEventListener("animationend", onAnimationEnd);
	dialogEl.value?.removeEventListener("animationcancel", onAnimationEnd);
});

__expose({ open, close });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("dialog", {
    ref_key: "dialogEl",
    ref: dialogEl,
    class: _normalizeClass(["shared-dialog tw", [
			slideIn.value ? 'dialog-animate-in' : 'dialog-animate-out',
			{
				'is-narrow': _ctx.narrow,
				'is-wide': _ctx.wide,
				'max-h-full': _ctx.fullMaxHeight,
			},
		]]),
    style: _normalizeStyle({ '--animation-duration': _unref(animationDurationMs) }),
    "aria-labelledby": _ctx.headline ? _unref(uuid) : undefined,
    tabindex: "-1",
    onCancel: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (close('escapeKey')), ["prevent"])),
    onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (!_ctx.preventDismiss && close('overlay')), ["self","stop"]))
  }, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.preventDismiss)
        ? (_openBlock(), _createBlock(CloseButton, {
            key: 0,
            class: "right-4 top-4",
            "data-test-dialog-close-button": "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (close('closeButton')))
          }))
        : _createCommentVNode("", true),
      (_ctx.headline)
        ? (_openBlock(), _createElementBlock("h2", {
            key: 1,
            id: _unref(uuid),
            class: "ocm-headline2 border-b-2 border-b-gray-30 pb-3.5 pl-4 pr-16 pt-4 tablet-down:hyphens-auto tablet-down:break-words",
            "data-test-modal-headline": ""
          }, _toDisplayString(_ctx.headline), 9, _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["pb-4", [
					_ctx.noScrollbar ? 'flex flex-col overflow-y-auto' : 'o-scrollbar overscroll-contain',
					{
						'lg-down:pb-9': !_ctx.closeActionButton,
						'px-4 pt-6': !_ctx.noBodyPadding,
					},
				]])
      }, [
        (_ctx.$slots.headerblock)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["-mt-5 bg-gray-20 px-4 py-9", { '-mx-4': !_ctx.noBodyPadding }])
            }, [
              _renderSlot(_ctx.$slots, "headerblock")
            ], 2))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default")
      ], 2),
      (_ctx.closeActionButton)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "footer"),
            _createVNode(SharedButton, {
              class: "min-w-[calc(var(--spacing-base-unit)*25)]",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (close('actionButton')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.closeActionButtonText), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ], 46, _hoisted_1))
}
}

})