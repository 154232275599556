import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-black" }
const _hoisted_2 = { class: "absolute right-4" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "pointer-events-none absolute top-0 flex min-h-[60px] w-full items-center justify-between p-4"
}

import { Ref, ref, computed, watch, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import SvgIcon from "@SharedVueComponents/SvgIcon/SvgIcon.vue";
import { FileUpload } from "@/types/image.type";
import SharedSpinner from "@SharedVueComponents/SharedSpinner.vue";
import {
	formatFileSize,
	acceptedFiles,
	imageUploadSizeOverLimit,
	invalidTypeFieldNames,
	isAnyImageCompressing,
} from "@/composables/use-imageUpload";
import { trackImageWrongType, trackImageCompressionError } from "@/composables/use-trackingErrors";
import { compressImage } from "@/main";

interface InputFileEvent extends Event {
	target: HTMLInputElement;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FormKitImage',
  props: {
    modelValue: {},
    label: {},
    name: {},
    validation: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();

const emit = __emit;

const hasWrongTypeError: Ref = ref(false);

const isCompressing = ref(false);

const wrongFileTypeErrorMessage = t("imageUploadPage.errorFileTyp", [acceptedFiles]);

const handleChange = (event: InputFileEvent): void => {
	hasWrongTypeError.value = !isValidFileType(event.target.value);
	hasWrongTypeError.value
		? invalidTypeFieldNames.value.add(props.name)
		: invalidTypeFieldNames.value.delete(props.name);
	if (hasWrongTypeError.value) trackImageWrongType(t);
};

const props = __props;

const handleDelete = (): void => {
	hasWrongTypeError.value = false;
	theModel.value = [];
	invalidTypeFieldNames.value.delete(props.name);
};

const isValidFileType = (fileNameParam?: string): boolean => {
	const fileName = fileNameParam || theModel.value[0]?.name;
	const acceptedFileTypes = acceptedFiles.split(",").map((str) => str.trim().replace(".", ""));
	const fileType = fileName.split(".").pop() || "";
	return acceptedFileTypes.includes(fileType);
};

const theModel = computed({
	get: () => props.modelValue,
	set: (value) => emit("update:modelValue", value),
});

watch(theModel, () => {
	// only start compressing if there is a new image
	if (theModel.value.length === 0 || isCompressing.value) return;
	compressImageLocal();
});

async function compressImageLocal() {
	const image = theModel.value[0].file;
	if (!image) return;
	setIsCompressing(true);
	compressImage(image)
		.then((compressedImage) => {
			if (compressedImage) {
				theModel.value[0].file = compressedImage;
				nextTick(() => {
					setIsCompressing(false);
				});
			}
		})
		.catch((err) => {
			console.error(err);
			trackImageCompressionError(image.size, getFileType(image.name));
			nextTick(() => {
				setIsCompressing(false);
			});
		});
}

function setIsCompressing(value: boolean) {
	isCompressing.value = value;
	isAnyImageCompressing.value = value;
}

function getFileType(fileName?: string) {
	if (!fileName) return null;
	const parts = fileName.split(".");
	return parts.length > 1 ? parts.pop() : null;
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createBlock(_component_FormKit, {
    modelValue: theModel.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((theModel).value = $event)),
    name: _ctx.name,
    type: "file",
    label: _ctx.label,
    accept: _unref(acceptedFiles),
    validation: _ctx.validation,
    errors: [hasWrongTypeError.value ? _unref(wrongFileTypeErrorMessage) : ''],
    onChange: handleChange
  }, {
    fileName: _withCtx((context) => [
      (!isCompressing.value && context?._value[0])
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(["overflow-hidden text-ellipsis whitespace-nowrap", { 'border-error': _unref(imageUploadSizeOverLimit) || hasWrongTypeError.value }])
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(_unref(imageUploadSizeOverLimit) ? 'text-error' : 'text-gray-80')
            }, _toDisplayString(_unref(formatFileSize)(context._value[0]?.file.size)) + " - ", 3),
            _createElementVNode("span", _hoisted_1, _toDisplayString(context._value[0].name), 1)
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_2, [
        (isCompressing.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
              _createVNode(SharedSpinner, {
                class: "",
                "dark-spinner": ""
              })
            ]))
          : (_openBlock(), _createBlock(SvgIcon, {
              key: 1,
              "icon-name": "close",
              class: _normalizeClass(["text-black", { 'text-error': _unref(imageUploadSizeOverLimit) || hasWrongTypeError.value }])
            }, null, 8, ["class"]))
      ])
    ]),
    noFiles: _withCtx((context) => [
      (!context._value[0])
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(SvgIcon, {
              "icon-name": "upload",
              class: "mr-2 text-xl"
            }),
            _createVNode(SvgIcon, {
              "icon-name": "plus-thin",
              class: "ml-2 text-xl"
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    fileRemove: _withCtx(() => [
      _createElementVNode("button", {
        type: "button",
        class: "formkit-file-remove absolute right-4 h-5 w-5 opacity-0",
        onClick: handleDelete
      }, [
        _createVNode(SvgIcon, {
          "aria-label": "Entfernen",
          "icon-name": "close",
          class: _normalizeClass(["absolute right-4 text-black", { 'text-error': _unref(imageUploadSizeOverLimit) || hasWrongTypeError.value }])
        }, null, 8, ["class"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "name", "label", "accept", "validation", "errors"]))
}
}

})