<template>
	<button
		type="button"
		class="mt-4 flex w-full text-xs font-medium"
		:class="{ invisible: isButtonInvisible }"
		@click="goToPreviousStep()"
	>
		<SvgIcon class="mr-1 h-4 w-4" icon-name="arrow-thin-left" />
		{{ $t("back") }}
	</button>
</template>

<script setup lang="ts">
import { getCurrentInputStepIndex } from "@/composables/use-pageNavigation";
import { searchInput } from "@/composables/use-search";
import { resultResponse } from "@/queries/search.query";
import SvgIcon from "@SharedVueComponents/SvgIcon/SvgIcon.vue";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { HELP_PAGE_NAME, LOGIN_INFO_PAGE_NAME, SELECTION_PAGE_NAME, THANK_YOU_PAGE_NAME } from "@/types/routeName.type";
import { currentCollection, currentFormFlow } from "@/composables/use-polly";
import { publicationData } from "@/queries/publications.query";
import router from "@/router";

const isSearchActive = computed(() => resultResponse.value !== undefined);
const route = useRoute();

const isButtonInvisible = computed(
	() =>
		(route.name === SELECTION_PAGE_NAME && !isSearchActive.value) ||
		route.name === THANK_YOU_PAGE_NAME ||
		(!publicationData.value && getCurrentInputStepIndex() === 0)
);

const goToPreviousStep = (): void => {
	const currentFormStepIndex = getCurrentInputStepIndex();
	if (currentFormStepIndex === 0) goExitFormFlow();
	else if (currentFormStepIndex > 0) goBackInFormFlow(currentFormStepIndex);
	else goBackToHardcodedPage();
};

function goExitFormFlow(): void {
	router.push({
		name: HELP_PAGE_NAME,
		params: currentCollection.value ? { collectionId: currentCollection.value.id } : undefined,
		query: { backNavigationNavigation: "true" },
	});
}

function goBackInFormFlow(currentFormStep: number): void {
	router.push({ name: currentFormFlow.value[currentFormStep - 1] });
}

function goBackToHardcodedPage(): void {
	const currentRouteName = router.currentRoute.value.name;
	switch (currentRouteName) {
		case HELP_PAGE_NAME:
			router.push({ name: SELECTION_PAGE_NAME, query: searchInput.value ? { search: searchInput.value } : {} });
			break;
		case LOGIN_INFO_PAGE_NAME:
			router.push({
				name: HELP_PAGE_NAME,
				params: currentCollection.value ? { collectionId: currentCollection.value.id } : undefined,
				query: { backNavigationNavigation: "true" },
			});
			break;
		case SELECTION_PAGE_NAME:
			router.push({ name: SELECTION_PAGE_NAME });
			break;
		default:
			console.error(
				"Go back one step. Because of unexpected browser back/forward behavior this should never take effect"
			);
			router.go(-1);
	}
}
</script>
