import { error, resultResponse } from "@/queries/search.query";
import { ref } from "vue";

export const searchInput = ref("");

export const clearSearch = (): void => {
	searchInput.value = "";
	error.value = "";
	resultResponse.value = undefined;
};
