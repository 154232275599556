<template>
	<div class="tw">
		<label v-if="label" :for="name" class="mb-4 block text-sm font-semibold">{{ label }}</label>
		<div
			ref="dropdownRef"
			class="relative"
			:class="{
				'text-gray-50': disabled,
				'text-black': isOpen || (!!selectedOption && !disabled),
				'text-gray-80': !selectedOption && !isOpen && !disabled,
				'focus-within:text-black hover:text-black': !disabled,
			}"
		>
			<!-- native select for mobile -->
			<FormKit
				:id="name"
				ref="formkitSelectRef"
				v-model="selectedOption"
				:disabled="disabled"
				:name="name"
				:options="options"
				type="select"
				:placeholder="placeholder"
				:plugins="plugins"
				:validation="validation"
				:validation-visibility="validationVisibility"
				:validation-messages="validationMessages"
				:tabindex="isEm64 ? '-1' : undefined"
				:aria-hidden="isEm64 ? 'true' : undefined"
				:input-class="
					toClassString({
						'bg-arrowDownError border-error focus:shadow-insetBottomError': isInvalid,
						'focus:shadow-insetBottomBlack focus:bg-arrowDownBlack': !isInvalid,
						'bg-arrowDownGray80 hover:bg-arrowDownBlack border-gray-80': !isInvalid && !isOpen && !selectedOption,
						'bg-arrowDownBlack': !isInvalid && (isOpen || !!selectedOption),
						'shadow-insetBottomError': isInvalid && isOpen,
						'pt-[1rem]': !!selectedOption && !!placeholder,
					})
				"
			/>
			<!-- custom dropdwn for desktop -->
			<div class="peer relative hidden text-base leading-5 em64:absolute em64:top-0 em64:block em64:w-full">
				<button
					:disabled="disabled"
					class="relative flex h-15 w-full select-none items-center rounded-t-lg border-b px-4 outline-none focus:text-black disabled:text-gray-50"
					:class="{
						'border-error focus:shadow-insetBottomError': isInvalid,
						'focus:shadow-insetBottomBlack': !isInvalid,
						'shadow-insetBottomError': isOpen && isInvalid,
						'pointer-events-auto cursor-not-allowed border-gray-50': disabled,
						'hover:bg-gray-40': !disabled,
						'border-gray-80 hover:border-black hover:bg-gray-40': !isInvalid && !disabled,
						'shadow-insetBottomBlack': isOpen && !isInvalid,
						'bg-gray-40': isOpen,
						'bg-gray-20': !isOpen,
					}"
					type="button"
					:tabindex="isEm64 ? '0' : '-1'"
					:aria-hidden="isEm64 ? undefined : 'true'"
					@click="toggleOpen()"
				>
					<SvgIcon
						v-if="icons && icons[selectedIndex]"
						class="mr-2 h-5 w-5 shrink-0"
						:class="{ 'translate-y-[0.5rem]': placeholder }"
						:icon-name="icons[selectedIndex]"
					/>
					<div class="mr-1 flex-1 overflow-hidden whitespace-nowrap text-left" :class="{ 'pt-[1rem]': placeholder }">
						{{ selectedOption ? options[selectedOption] : "" }}
					</div>
					<SvgIcon
						class="h-5 w-5 shrink-0 transition-transform"
						:class="{ 'rotate-180': isOpen, 'text-error': isInvalid }"
						icon-name="arrow-down"
					/>
				</button>
				<ul
					ref="optionsListRef"
					class="absolute left-0 top-full z-10 w-full text-black-primary shadow-4"
					:class="{ hidden: !isOpen }"
					style="clip-path: inset(0 -20px -20px -20px)"
				>
					<li v-for="(option, key, i) in options" :key="key">
						<button
							class="flex h-12 w-full select-none items-center bg-white px-4 outline-none hover:bg-gray-40 hover:text-black focus:bg-gray-40 focus:text-black"
							:data-key="key"
							type="button"
							@click="selectOption(key)"
						>
							<SvgIcon v-if="icons && icons[i]" class="mr-2 h-5 w-5 shrink-0" :icon-name="icons[i]" />
							<div class="flex-1 text-left" :class="{ 'ml-7': icons && !icons[i] }">{{ option }}</div>
						</button>
					</li>
				</ul>
			</div>
			<div
				class="pointer-events-none absolute left-4 top-0 flex h-15 origin-left select-none items-center transition-[opacity,_transform]"
				:class="[
					{
						'text-error': isInvalid,
						'peer-hover:text-black': !isInvalid && !disabled,
						'text-gray-80': !isInvalid && selectedOption && !isOpen,
					},
					isOpen || selectedOption
						? '-translate-y-[0.928571428571429rem] scale-75 opacity-100'
						: 'opacity-0 em64:opacity-100',
				]"
			>
				{{ placeholder }}
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, nextTick } from "vue";
import SvgIcon from "@SharedVueComponents/SvgIcon/SvgIcon.vue";
import { useBreakpoints, onClickOutside, onKeyStroke } from "@vueuse/core";
import type { FormKitPlugin } from "@formkit/core";

const props = defineProps<{
	disabled?: boolean;
	icons?: string[];
	label?: string;
	modelValue?: string;
	name?: string;
	options: {
		[key: string]: string;
	};
	placeholder?: string;
	plugins?: FormKitPlugin[];
	validation?: string;
	validationVisibility?: string;
	validationMessages?: { [key: string]: string };
	value?: string;
}>();
const emit = defineEmits(["update:modelValue"]);

const isEm64 = useBreakpoints({ em64: "64em" }).greaterOrEqual("em64");
const isOpen = ref(false);
const selectedOption = props.value
	? ref(props.value)
	: computed({
			get() {
				return props.modelValue || "";
			},
			set(val: string) {
				emit("update:modelValue", val);
			},
		});

const selectOption = (key: string | number) => {
	selectedOption.value = key.toString();
	isOpen.value = false;
};

const selectedIndex = computed(() => Object.keys(props.options).findIndex((key) => key === selectedOption.value));

const dropdownRef = ref<HTMLDivElement | null>(null);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formkitSelectRef = ref<(HTMLElement & { node: any }) | null>(null);
const optionsListRef = ref<HTMLUListElement | null>(null);
const isInvalid = ref(false);

onClickOutside(dropdownRef, () => (isOpen.value = false));

onMounted(() => {
	formkitSelectRef.value?.node.on("message-added", async () => {
		await nextTick();
		const formKitState = formkitSelectRef.value?.node.context.state;
		isInvalid.value = !formKitState.valid && formKitState.validationVisible;
	});
});

const getFocusedIndex = () =>
	Object.keys(props.options).findIndex((key) => key === document.activeElement?.getAttribute("data-key"));

const focusOption = (index?: number) => {
	if (!optionsListRef.value) return;
	if (index) optionsListRef.value.querySelectorAll("button")[index].focus();
};
// eslint-disable-next-line complexity
const handleKeyUpDown = (e: KeyboardEvent, key: "up" | "down"): void => {
	if (isOpen.value) e.preventDefault();
	const focusedIndex = getFocusedIndex();
	const isFirstOrNoneFocused = focusedIndex < 1;
	const isLastFocused = focusedIndex >= Object.keys(props.options).length - 1;
	if (key === "up" && isFirstOrNoneFocused) return;
	if (key === "down" && isLastFocused) return;
	if (!isOpen.value || !isEm64.value) return;
	focusOption(key === "up" ? focusedIndex - 1 : focusedIndex + 1);
};

onKeyStroke("ArrowUp", (e: KeyboardEvent) => handleKeyUpDown(e, "up"));
onKeyStroke("ArrowDown", (e: KeyboardEvent) => handleKeyUpDown(e, "down"));
onKeyStroke("Escape", () => (isOpen.value = false));

const toggleOpen = () => {
	isOpen.value = !isOpen.value;
	if (isOpen.value && selectedIndex.value > -1) nextTick(() => focusOption(selectedIndex.value));
};

const toClassString = (classObj: { [key: string]: boolean }) =>
	Object.entries(classObj)
		.reduce((classStr, [classes, condition]) => {
			if (!condition) return classStr;
			return `${classStr} ${classes}`;
		}, "")
		.trim();
</script>

<style scoped>
.formkit-wrapper {
	@media (min-width: 64em) {
		visibility: hidden;
	}
}
</style>
