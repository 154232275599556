import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-6 mt-10 flex justify-center py-3" }
const _hoisted_2 = { class: "my-4 mb-2 text-xl font-semibold" }
const _hoisted_3 = { class: "mb-20" }

import { contextPath } from "@ocm/services/ocm-object";
import SvgIcon from "@SharedVueComponents/SvgIcon/SvgIcon.vue";
import SharedButton from "@SharedVueComponents/SharedButton.vue";
import { SELECTION_PAGE_NAME } from "@/types/routeName.type";
import { resetInputData } from "@/composables/use-messageData";
import { onMounted } from "vue";
import { areAllStepsCompleted, completedSteps } from "@/composables/use-formValidity";
import { startContactForm } from "@/composables/use-pageNavigation";
import { currentCollection } from "@/composables/use-polly";


export default /*@__PURE__*/_defineComponent({
  __name: 'ThankYouPage',
  setup(__props) {

onMounted(() => {
	const areNotAllStepsCompleted = !areAllStepsCompleted.value; // get state before resetting the form
	resetContactForm();
	if (areNotAllStepsCompleted) startContactForm(currentCollection.value?.id, true);
});

function resetContactForm(): void {
	completedSteps.value.clear();
	resetInputData();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(SvgIcon, {
        "icon-name": "send-email",
        class: "h-[60px] w-[60px]"
      })
    ]),
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("thankYouPage.headline")), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("thankYouPage.text")), 1),
    _createVNode(SharedButton, {
      href: `${_unref(contextPath)}/`,
      variant: "primary",
      class: "mb-4 w-3/4 lg-down:w-full"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("thankYouPage.cta")), 1)
      ]),
      _: 1
    }, 8, ["href"]),
    _createVNode(SharedButton, {
      to: { name: _unref(SELECTION_PAGE_NAME) },
      variant: "secondary",
      class: "w-3/4 lg-down:w-full"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("thankYouPage.ctaSecondary")), 1)
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}
}

})