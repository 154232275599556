import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["aria-hidden", "aria-labelledby"]
const _hoisted_2 = ["id"]
const _hoisted_3 = ["xlink:href", "href"]


export default /*@__PURE__*/_defineComponent({
  __name: 'SvgIcon',
  props: {
    iconName: {},
    title: {}
  },
  setup(__props: any) {



const titleId = (Math.random() + 1).toString(36).substring(2);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(["svg-icon", `icon-${_ctx.iconName}`]),
    "aria-hidden": !_ctx.title ? 'true' : undefined,
    "aria-labelledby": _ctx.title ? _unref(titleId) : undefined
  }, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("title", {
          key: 0,
          id: _unref(titleId)
        }, _toDisplayString(_ctx.title), 9, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("use", {
      "xlink:href": `#icon-${_ctx.iconName}`,
      href: `#icon-${_ctx.iconName}`
    }, null, 8, _hoisted_3)
  ], 10, _hoisted_1))
}
}

})