import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { durationTimeInMs } from "../../composables/use-transition-expand-duration";


export default /*@__PURE__*/_defineComponent({
  __name: 'TransitionExpand',
  emits: ["afterLeave"],
  setup(__props, { emit: __emit }) {

const enter = (element: HTMLElement) => {
	element.style.height = "auto";
	const height = getComputedStyle(element).height;
	element.style.height = "0";

	getComputedStyle(element).height;

	requestAnimationFrame(() => {
		element.style.height = height;
	});
};

const afterEnter = (element: HTMLElement) => (element.style.height = "auto");

const leave = (element: HTMLElement): void => {
	element.style.height = getComputedStyle(element).height;

	getComputedStyle(element).height;

	requestAnimationFrame(() => {
		element.style.height = "0";
	});
};

const emit = __emit;
const onAfterLeave = () => {
	emit("afterLeave");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, {
    "enter-active-class": `overflow-hidden transition-height duration-${_unref(durationTimeInMs)}`,
    "leave-active-class": `overflow-hidden transition-height duration-${_unref(durationTimeInMs)}`,
    onEnter: enter,
    onAfterEnter: afterEnter,
    onLeave: leave,
    onAfterLeave: onAfterLeave
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["enter-active-class", "leave-active-class"]))
}
}

})