<template>
	<div>
		<HeadlineText
			>{{ $t("descriptionPage.headline") }} <template #sub>{{ $t("descriptionPage.help") }}</template></HeadlineText
		>
		<FormKit
			ref="formRef"
			type="form"
			:submit-label="$t('next')"
			:config="{ validationVisibility: 'submit' }"
			@submit="goToNextFormStep()"
		>
			<FormKit
				v-model="description"
				type="textarea"
				outer-class="$reset mb-20"
				validation="required"
				:placeholder="$t('descriptionPage.placeholder')"
				:plugins="[useErrorTracking().trackingPlugin]"
				:name="$t('descriptionPage.description')"
			/>
		</FormKit>
	</div>
</template>

<script setup lang="ts">
import { goToNextFormStep } from "@/composables/use-pageNavigation";
import { description } from "@/composables/use-messageData";
import { useErrorTracking } from "@/composables/use-trackingErrors";
import HeadlineText from "@/components/HeadlineText.vue";
import { onBeforeMount, onMounted, ref } from "vue";
import { getAndAutofillContactData } from "@/composables/use-autofill";
import { useI18n } from "vue-i18n";
import { useRerouteToValidStep } from "@/composables/use-formValidity";
const { t } = useI18n({});
const formRef = ref();

onMounted(() => {
	useRerouteToValidStep(formRef);
});

onBeforeMount(async () => {
	// additional early call to pre-fetch contact data
	await getAndAutofillContactData(t);
});
</script>
