<template>
	<div>
		<TransitionSection>
			<LoadingSpinner v-if="loading" />
		</TransitionSection>
		<HeadlineText>{{ $t("personalDataPage.headline") }}</HeadlineText>
		<FormKit
			ref="formRef"
			type="form"
			:submit-label="$t('personalDataPage.sendRequest')"
			:config="{ validationVisibility: 'submit' }"
			:errors="[$t(mailErrorText)]"
			@submit="submit"
		>
			<div class="mt-8">
				<FormKit
					v-model="salutation"
					type="ocmChipRadio"
					:name="$t('personalDataPage.salutation')"
					validation="required"
					:plugins="[useErrorTracking().trackingPlugin]"
					:options="[$t('personalDataPage.mr'), $t('personalDataPage.mrs')]"
				>
				</FormKit>
			</div>

			<FormKit
				v-model="firstName"
				type="ocmTextInput"
				input-type="text"
				:label="$t('personalDataPage.firstName')"
				:name="$t('personalDataPage.firstName')"
				validation="required"
				:plugins="[useErrorTracking().trackingPlugin]"
			/>
			<FormKit
				v-model="lastName"
				type="ocmTextInput"
				input-type="text"
				:label="$t('personalDataPage.lastName')"
				:name="$t('personalDataPage.lastName')"
				validation="required"
				:plugins="[useErrorTracking().trackingPlugin]"
			/>
			<FormKit
				v-model="email"
				type="ocmTextInput"
				input-type="email"
				:label="$t('personalDataPage.email')"
				:name="$t('personalDataPage.email')"
				validation="required|email"
				:plugins="[useErrorTracking().trackingPlugin]"
				:disabled="emailDisabled"
			/>
			<FormKit
				v-model="phone"
				type="ocmTextInput"
				input-type="tel"
				:label="$t('personalDataPage.phone')"
				:name="$t('personalDataPage.phone')"
				:plugins="[useErrorTracking().trackingPlugin]"
			/>
			<vue-recaptcha
				v-if="captchaData?.captchaActive"
				ref="captchaEl"
				:sitekey="captchaData.publicKey"
				:load-recaptcha-script="false"
				size="invisible"
				@verify="verifyCaptcha"
				@expired="expiredCaptcha"
				@error="errorCaptcha"
			/>
			<p class="mb-10 text-gray-70">
				<i18n-t tag="p" class="mt-4" keypath="personalDataPage.dataText">
					<BtnLink class="text-gray-70" target="_blank" :href="`${contextPath}/${$t('personalDataPage.dataLink')}/`">{{
						$t("personalDataPage.dataTextPolicy")
					}}</BtnLink
					>.
				</i18n-t>
			</p>
		</FormKit>
		<span v-if="captchaData?.captchaActive" class="text-gray-70">{{ $t("personalDataPage.captchaText") }} </span>
	</div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, onMounted } from "vue";
import { VueRecaptcha } from "vue-recaptcha";
import { contextPath } from "@ocm/services/ocm-object";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import HeadlineText from "@/components/HeadlineText.vue";
import BtnLink from "@/components/BtnLink.vue";
import { sendMail, mailErrorText } from "@/composables/use-mail";
import { phone, email, lastName, firstName, salutation, emailDisabled } from "@/composables/use-messageData";
import { useErrorTracking } from "@/composables/use-trackingErrors";
import { getAndAutofillContactData } from "@/composables/use-autofill";
import { useI18n } from "vue-i18n";
import { useRerouteToValidStep } from "@/composables/use-formValidity";
import { getCaptchaData, captchaData } from "@/queries/captcha.config";
import TransitionSection from "@/components/TransitionSection.vue";

const { t } = useI18n({});
const formRef = ref();

const captchaEl = ref<VueRecaptcha>();
const loading = ref(false);

const hideLoadingSpinner = (): void => {
	loading.value = false;
};
const showLoadingSpinner = (): void => {
	loading.value = true;
};

const captchaIsVerified = ref(false);
const captchaToken = ref();

const submit = async (): Promise<void> => {
	if (captchaData.value?.captchaActive) {
		await captchaEl?.value?.execute();
	}
	if (!captchaData.value?.captchaActive || captchaIsVerified.value) {
		showLoadingSpinner();
		await sendMail(captchaToken.value);
		hideLoadingSpinner();
	}
};

onBeforeMount(async () => {
	await getCaptchaData();
	await getAndAutofillContactData(t);
});

onMounted(() => {
	useRerouteToValidStep(formRef);
});

const verifyCaptcha = async (token: string): Promise<void> => {
	showLoadingSpinner();
	await sendMail(token);
	captchaIsVerified.value = true;
	captchaToken.value = token;
	hideLoadingSpinner();
};

const expiredCaptcha = (): void => {
	mailErrorText.value = "generalError";
	console.log("expired");
};

const errorCaptcha = (): void => {
	mailErrorText.value = "generalError";
	console.log("error captcha");
};
</script>
