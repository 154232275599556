<template>
	<InfoBox v-if="data?.publication?.announcement?.schema_elements?.length" class="mb-6"
		><div
			v-if="data.publication.announcement.name"
			class="mb-2 font-medium"
			v-html="data.publication.announcement.name"
		/>
		<div v-for="(content, i) in data.publication.announcement.schema_elements" :key="i" v-html="content.content" />
	</InfoBox>
</template>

<script setup lang="ts">
import { onBeforeMount } from "vue";
import InfoBox from "./InfoBox.vue";
import { data, getAnnouncements } from "@/queries/announcements.query";

onBeforeMount(async () => {
	if (!data.value) {
		await getAnnouncements();
	}
});
</script>
