<template>
	<FormKit
		v-model="theModel"
		:name="name"
		type="file"
		:label="label"
		:accept="acceptedFiles"
		:validation="validation"
		:errors="[hasWrongTypeError ? wrongFileTypeErrorMessage : '']"
		@change="handleChange"
	>
		<template #fileName="context">
			<span
				v-if="!isCompressing && context?._value[0]"
				class="overflow-hidden text-ellipsis whitespace-nowrap"
				:class="{ 'border-error': imageUploadSizeOverLimit || hasWrongTypeError }"
			>
				<span :class="imageUploadSizeOverLimit ? 'text-error' : 'text-gray-80'"
					>{{ formatFileSize(context._value[0]?.file.size) }} -
				</span>
				<span class="text-black">{{ context._value[0].name }}</span>
			</span>
			<span class="absolute right-4">
				<span v-if="isCompressing">
					<SharedSpinner class="" dark-spinner />
				</span>
				<SvgIcon
					v-else
					icon-name="close"
					class="text-black"
					:class="{ 'text-error': imageUploadSizeOverLimit || hasWrongTypeError }"
				/>
			</span>
		</template>
		<template #noFiles="context">
			<div
				v-if="!context._value[0]"
				class="pointer-events-none absolute top-0 flex min-h-[60px] w-full items-center justify-between p-4"
			>
				<SvgIcon icon-name="upload" class="mr-2 text-xl" />
				<SvgIcon icon-name="plus-thin" class="ml-2 text-xl" />
			</div>
		</template>
		<template #fileRemove>
			<button type="button" class="formkit-file-remove absolute right-4 h-5 w-5 opacity-0" @click="handleDelete">
				<SvgIcon
					aria-label="Entfernen"
					icon-name="close"
					class="absolute right-4 text-black"
					:class="{ 'text-error': imageUploadSizeOverLimit || hasWrongTypeError }"
				/>
			</button>
		</template>
	</FormKit>
</template>

<script setup lang="ts">
import { Ref, ref, computed, watch, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import SvgIcon from "@SharedVueComponents/SvgIcon/SvgIcon.vue";
import { FileUpload } from "@/types/image.type";
import SharedSpinner from "@SharedVueComponents/SharedSpinner.vue";
import {
	formatFileSize,
	acceptedFiles,
	imageUploadSizeOverLimit,
	invalidTypeFieldNames,
	isAnyImageCompressing,
} from "@/composables/use-imageUpload";
import { trackImageWrongType, trackImageCompressionError } from "@/composables/use-trackingErrors";
import { compressImage } from "@/main";

const { t } = useI18n();

const emit = defineEmits(["update:modelValue"]);

const hasWrongTypeError: Ref = ref(false);

const isCompressing = ref(false);

const wrongFileTypeErrorMessage = t("imageUploadPage.errorFileTyp", [acceptedFiles]);

interface InputFileEvent extends Event {
	target: HTMLInputElement;
}

const handleChange = (event: InputFileEvent): void => {
	hasWrongTypeError.value = !isValidFileType(event.target.value);
	hasWrongTypeError.value
		? invalidTypeFieldNames.value.add(props.name)
		: invalidTypeFieldNames.value.delete(props.name);
	if (hasWrongTypeError.value) trackImageWrongType(t);
};

const props = defineProps<{
	modelValue: Array<FileUpload>;
	label: string;
	name: string;
	validation?: string;
}>();

const handleDelete = (): void => {
	hasWrongTypeError.value = false;
	theModel.value = [];
	invalidTypeFieldNames.value.delete(props.name);
};

const isValidFileType = (fileNameParam?: string): boolean => {
	const fileName = fileNameParam || theModel.value[0]?.name;
	const acceptedFileTypes = acceptedFiles.split(",").map((str) => str.trim().replace(".", ""));
	const fileType = fileName.split(".").pop() || "";
	return acceptedFileTypes.includes(fileType);
};

const theModel = computed({
	get: () => props.modelValue,
	set: (value) => emit("update:modelValue", value),
});

watch(theModel, () => {
	// only start compressing if there is a new image
	if (theModel.value.length === 0 || isCompressing.value) return;
	compressImageLocal();
});

async function compressImageLocal() {
	const image = theModel.value[0].file;
	if (!image) return;
	setIsCompressing(true);
	compressImage(image)
		.then((compressedImage) => {
			if (compressedImage) {
				theModel.value[0].file = compressedImage;
				nextTick(() => {
					setIsCompressing(false);
				});
			}
		})
		.catch((err) => {
			console.error(err);
			trackImageCompressionError(image.size, getFileType(image.name));
			nextTick(() => {
				setIsCompressing(false);
			});
		});
}

function setIsCompressing(value: boolean) {
	isCompressing.value = value;
	isAnyImageCompressing.value = value;
}

function getFileType(fileName?: string) {
	if (!fileName) return null;
	const parts = fileName.split(".");
	return parts.length > 1 ? parts.pop() : null;
}
</script>
