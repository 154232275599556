<template>
	<div v-if="fetching" class="grid auto-rows-[minmax(0,84px)] grid-cols-2 gap-4">
		<BasicSkeleton v-for="n in 8" :key="`skeleton-${n}`" class="rounded-lg" />
	</div>
	<div v-else-if="error">{{ $t("generalError") }}</div>
	<div
		v-else-if="publicationData?.publication?.collections && isEmptySearchResult"
		class="grid auto-rows-[minmax(0,84px)] grid-cols-2 gap-4"
	>
		<BaseCard v-for="(collection, i) in publicationData.publication.collections" :key="i" :collection="collection" />
	</div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount } from "vue";
import BasicSkeleton from "@SharedVueComponents/BasicSkeleton/BasicSkeleton.vue";
import { startContactForm } from "@/composables/use-pageNavigation";
import { error, fetching, publicationData } from "@/queries/publications.query";
import BaseCard from "@/components/BaseCard.vue";
import { resultResponse } from "@/queries/search.query";

const redirectNoPublications = () => {
	if (!publicationData.value?.publication) {
		startContactForm("fallback");
	}
};

const isEmptySearchResult = computed(() => !resultResponse.value || resultResponse.value.length === 0);

onBeforeMount(() => {
	redirectNoPublications();
});
</script>
