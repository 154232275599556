import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, Teleport as _Teleport } from "vue"

const _hoisted_1 = ["role"]
const _hoisted_2 = {
  key: 1,
  class: "ocm-headline2 border-b-2 border-b-gray-30 pb-3.5 pl-4 pr-16 pt-4 tablet-down:hyphens-auto tablet-down:break-words",
  "data-test-modal-headline": ""
}
const _hoisted_3 = {
  key: 2,
  class: "flex justify-end px-4 pb-4"
}

import { ref, watch, onBeforeUnmount } from "vue";
import CloseButton from "../CloseButton/CloseButton.vue";
import SharedButton from "../SharedButton.vue";
import { animationDurationMs } from "../../../services/services.modal";


export default /*@__PURE__*/_defineComponent({
  __name: 'Vue3Modal',
  props: {
    ifShowIsOpen: { type: Boolean, default: true },
    wide: { type: Boolean },
    fullMaxHeight: { type: Boolean },
    narrow: { type: Boolean },
    preventDismiss: { type: Boolean },
    closeActionButton: { type: Boolean },
    closeActionButtonText: { default: "Ok" },
    headline: {},
    noScrollbar: { type: Boolean },
    noBodyPadding: { type: Boolean }
  },
  emits: ["closing", "closed"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;
const emit = __emit;

const slideIn = ref(true);
watch(
	() => props.ifShowIsOpen,
	(newVal) => (slideIn.value = newVal)
);

const timeout = ref<number>();
onBeforeUnmount(() => {
	clearTimeout(timeout.value);
});

const close = (triggeredBy = "") => {
	slideIn.value = false;
	emit("closing", triggeredBy);
	timeout.value = window.setTimeout(() => {
		emit("closed");
	}, animationDurationMs);
};
__expose({ close });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(["tw fallback-modal modalOverlay animated fixed left-0 top-0 z-[999] h-full w-screen items-end justify-center lg:items-center", [_ctx.$attrs.class, slideIn.value ? 'modalFadeIn' : 'modalFadeOut']]),
      style: _normalizeStyle({ '--animationDuration': `${_unref(animationDurationMs)}ms` }),
      onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (!_ctx.preventDismiss && close('overlay')), ["self","stop"]))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["modal animated relative flex w-full flex-col bg-white text-black-primary", [
					slideIn.value ? 'modalSlideInUp' : 'modalSlideOutDown',
					{
						'is-narrow': _ctx.narrow,
						'is-wide': _ctx.wide,
						'max-h-full': _ctx.fullMaxHeight,
					},
				]]),
        role: _ctx.$attrs.role ?? 'dialog',
        onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
      }, [
        (!_ctx.preventDismiss)
          ? (_openBlock(), _createBlock(CloseButton, {
              key: 0,
              class: "right-4 top-4",
              "data-test-modal-close-button": "",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (close('closeButton')))
            }))
          : _createCommentVNode("", true),
        (_ctx.headline)
          ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.headline), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["pb-4", [
						_ctx.noScrollbar ? 'flex flex-col overflow-y-hidden' : 'o-scrollbar overscroll-contain',
						{
							'lg-down:pb-9': !_ctx.closeActionButton,
							'px-4 pt-6': !_ctx.noBodyPadding,
						},
					]])
        }, [
          (_ctx.$slots.headerblock)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["-mt-5 bg-gray-20 px-4 py-9", { '-mx-4': !_ctx.noBodyPadding }])
              }, [
                _renderSlot(_ctx.$slots, "headerblock")
              ], 2))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default")
        ], 2),
        (_ctx.closeActionButton)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "footer"),
              _createVNode(SharedButton, {
                class: "min-w-[calc(var(--spacing-base-unit)*25)]",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (close('actionButton')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.closeActionButtonText), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1)
    ], 6), [
      [_vShow, _ctx.ifShowIsOpen]
    ])
  ]))
}
}

})