import { country } from "@ocm/services/ocm-object";

export const contactPhoneNumber = (): string => {
	switch (country) {
		case "ch":
			return "+41 (0) 43 - 5087740";
		case "at":
			return "+43 (0) 720 - 88 23 90";
		default:
			return "+49 (0) 7123 - 17 89 978";
	}
};
