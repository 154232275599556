import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-20 grid grid-cols-[min-content,1fr] items-center gap-x-2" }

import SvgIcon from "@SharedVueComponents/SvgIcon/SvgIcon.vue";
import InfoModal from "@SharedVueComponents/Modal/InfoModal.vue";
import HeadlineText from "@/components/HeadlineText.vue";
import { goToNextFormStep } from "@/composables/use-pageNavigation";
import { useErrorTracking } from "@/composables/use-trackingErrors";
import { orderNumber } from "@/composables/use-messageData";
import { onMounted, ref } from "vue";
import { useRerouteToValidStep } from "@/composables/use-formValidity";


export default /*@__PURE__*/_defineComponent({
  __name: 'OrderPage',
  setup(__props) {

const formRef = ref();

onMounted(() => {
	useRerouteToValidStep(formRef);
});

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(HeadlineText, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("orderPage.headline")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_FormKit, {
      ref_key: "formRef",
      ref: formRef,
      type: "form",
      "submit-label": _ctx.$t('next'),
      config: { validationVisibility: 'submit' },
      onSubmit: _cache[1] || (_cache[1] = ($event: any) => (_unref(goToNextFormStep)()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FormKit, {
          modelValue: _unref(orderNumber),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(orderNumber) ? (orderNumber).value = $event : null)),
          name: "Bestellnummer",
          label: "Bestellnummer",
          type: "ocmTextInput",
          "input-type": "number",
          plugins: [_unref(useErrorTracking)().trackingPlugin],
          validation: "required|matches:/^[2-4][0-9]{7}$/",
          "validation-messages": {
					matches: _ctx.$t('orderPage.lengthErrorMessage'),
				}
        }, null, 8, ["modelValue", "plugins", "validation-messages"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(SvgIcon, {
            "icon-name": "info",
            class: "h-4.5 w-4.5"
          }),
          _createVNode(InfoModal, {
            headline: _ctx.$t('orderPage.helpHeadline')
          }, {
            trigger: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("orderPage.helpHeadline")), 1)
            ]),
            default: _withCtx(() => [
              _createTextVNode(" " + _toDisplayString(_ctx.$t("orderPage.helpText")), 1)
            ]),
            _: 1
          }, 8, ["headline"])
        ])
      ]),
      _: 1
    }, 8, ["submit-label"])
  ]))
}
}

})