import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'TransitionExpand',
  setup(__props) {

const enter = (element: HTMLElement): void => {
	const width = getComputedStyle(element).width;

	element.style.width = width;
	element.style.position = "absolute";
	element.style.visibility = "hidden";
	element.style.height = "auto";

	const height = getComputedStyle(element).height;

	element.style.width = null;
	element.style.position = null;
	element.style.visibility = null;
	element.style.height = 0;

	// Force repaint to make sure the
	// animation is triggered correctly.
	getComputedStyle(element).height;

	// Trigger the animation.
	// We use `requestAnimationFrame` because we need
	// to make sure the browser has finished
	// painting after setting the `height`
	// to `0` in the line above.
	requestAnimationFrame(() => {
		element.style.height = height;
	});
};

const afterEnter = (element: HTMLElement): void => (element.style.height = "auto");

const leave = (element: HTMLElement): void => {
	const height = getComputedStyle(element).height;

	element.style.height = height;

	// Force repaint to make sure the
	// animation is triggered correctly.
	getComputedStyle(element).height;

	requestAnimationFrame(() => {
		element.style.height = 0;
	});
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, {
    name: "expand",
    onEnter: enter,
    onAfterEnter: afterEnter,
    onLeave: leave
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}
}

})