import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["aria-hidden"]
const _hoisted_3 = { class: "p-4" }

import { inject, onMounted, ref, watch } from "vue";
import SvgIcon from "../SvgIcon/SvgIcon.vue";
import TransitionExpand from "./TransitionExpand.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SharedExpandable',
  props: {
    disabled: { type: Boolean },
    isExpanded: { type: Boolean },
    title: {}
  },
  emits: ["header-clicked"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const isExpanded = ref();
const isExpanding = ref(false);
const emit = __emit;

onMounted(() => {
	isExpanded.value = props.isExpanded;
});

const handleClick = () => {
	emit("header-clicked");

	if (isExpanded.value) {
		isExpanded.value = false;
		return;
	}
	isExpanded.value = true;
	isExpanding.value = true;
	collapseOthers();
};

const collapseOthers = inject("collapseOthers", () => {});
const isCollapsing = inject("isCollapsing", ref(false));
watch(
	() => isCollapsing.value,
	(newVal) => {
		if (!newVal) return;
		isExpanded.value = isExpanding.value;
		isExpanding.value = false;
	}
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("dt", null, [
      _createElementVNode("button", {
        class: _normalizeClass(["relative flex w-full select-none justify-between border-b border-gray-30 p-4 text-left text-sm outline-none before:absolute before:-left-[2px] before:-top-[2px] before:z-10 before:h-[calc(100%_+_4px)] before:w-[calc(100%_+_4px)] before:rounded before:border-2 before:border-transparent focus-visible:before:border-black-primary", {
					'bg-gray-30': isExpanded.value,
					'cursor-not-allowed text-gray-50': _ctx.disabled,
					'text-black-primary supportsHover:hover:bg-gray-30': !_ctx.disabled,
				}]),
        disabled: _ctx.disabled,
        style: {"-webkit-tap-highlight-color":"rgba(0, 0, 0, 0)"},
        onClick: handleClick
      }, [
        _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
        (isExpanded.value)
          ? (_openBlock(), _createBlock(SvgIcon, {
              key: 0,
              class: "ml-4 h-5 w-5 shrink-0",
              "icon-name": "minus-thin"
            }))
          : (_openBlock(), _createBlock(SvgIcon, {
              key: 1,
              class: "ml-4 h-5 w-5 shrink-0",
              "icon-name": "plus-thin"
            }))
      ], 10, _hoisted_1)
    ]),
    _createVNode(TransitionExpand, null, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("dd", {
          class: "border-b border-gray-30 text-sm",
          "aria-hidden": isExpanded.value ? 'false' : 'true'
        }, [
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ], 8, _hoisted_2), [
          [_vShow, isExpanded.value]
        ])
      ]),
      _: 3
    })
  ]))
}
}

})