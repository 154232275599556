import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tw border-t border-gray-30" }

import { nextTick, provide, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SharedExpandableGroup',
  props: {
    stayOpen: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const isCollapsing = ref(false);

provide("isCollapsing", isCollapsing);
provide("collapseOthers", () => {
	if (props.stayOpen) return;
	isCollapsing.value = true;
	nextTick(() => (isCollapsing.value = false));
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("dl", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})