import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-label"]

import SvgIcon from "../SvgIcon/SvgIcon.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CloseButton',
  props: {
    inverted: { type: Boolean },
    ariaLabel: { default: "Schließen" }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["absolute z-[15] grid h-8 w-8 place-content-center rounded-full border", [
			_ctx.inverted
				? 'bg-white text-black-primary hover:bg-gray-40 active:bg-gray-60'
				: 'border-black-primary bg-black-primary text-white hover:border-gray-90 hover:bg-gray-90 active:border-gray-80 active:bg-gray-80',
		]]),
    "aria-label": _ctx.ariaLabel,
    style: {"-webkit-tap-highlight-color":"transparent"}
  }, [
    _createVNode(SvgIcon, {
      "icon-name": "close-fat",
      class: "h-3.5 w-3.5"
    })
  ], 10, _hoisted_1))
}
}

})