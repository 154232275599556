<template>
	<div id="contact" ref="contactRef" class="tw relative m-auto max-w-2xl px-4 pb-20">
		<BackButton />
		<TransitionSection>
			<h2
				v-if="!isCurrentPage(THANK_YOU_PAGE_NAME) && !isCurrentPage(LOGIN_INFO_PAGE_NAME)"
				class="mt-4 mb-6 text-xl font-semibold"
			>
				{{ isCurrentPage(SELECTION_PAGE_NAME) ? $t("headline") : currentCollection?.name }}
			</h2>
		</TransitionSection>

		<InputStepsProgress />
		<router-view v-slot="{ Component }">
			<TransitionSection>
				<component :is="Component" />
			</TransitionSection>
		</router-view>
	</div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { currentCollection } from "@/composables/use-polly";
import "@/composables/use-trackingVirtualPageViews";
import BackButton from "@/components/BackButton.vue";
import TransitionSection from "@/components/TransitionSection.vue";
import { isCurrentPage } from "@/composables/use-pageNavigation";
import { THANK_YOU_PAGE_NAME, LOGIN_INFO_PAGE_NAME, SELECTION_PAGE_NAME } from "@/types/routeName.type";
import InputStepsProgress from "@/components/InputStepsProgress.vue";

const contactRef = ref<HTMLDivElement>();
</script>
