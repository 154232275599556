import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, unref as _unref, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pt-4 font-medium"
}
const _hoisted_2 = { class: "mb-4 font-semibold" }

import { computed, Ref, ref } from "vue";
import SvgIcon from "@SharedVueComponents/SvgIcon/SvgIcon.vue";
import { sendFeedback, showFeedbackSuccessIds, feedbackErrorText } from "@/queries/feedback.query";
import { trackNotHelpfulBtn } from "@/composables/use-trackingInteractions";
import { useErrorTracking } from "@/composables/use-trackingErrors";
import { useI18n } from "vue-i18n";
import BaseDropdown from "@/components/BaseDropdown.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseFeedback',
  props: {
    articleId: {},
    articleName: {}
  },
  setup(__props: any) {

const props = __props;

const showFeedbackSuccess = computed(
	(): boolean => !!showFeedbackSuccessIds.value.find((id: string) => id === props.articleId)
);

const feedbackReason = computed(() => optionTranslationKeys.indexOf(selection.value));
const content: Ref<string> = ref("");
const selection: Ref<string> = ref("");

const showFeedbackOptions: Ref<boolean> = ref(true);

const negativeFeedback = (): void => {
	showFeedbackOptions.value = false;
	trackNotHelpfulBtn(props.articleName);
};

const optionTranslationKeys = ["unclear", "containsErrors", "wrongQuestion", "stillNeedHelp"];

const { t } = useI18n();
const feedbackOptions = optionTranslationKeys.reduce(
	(acc, key) => ({ ...acc, [key]: t(`helpPage.reasons.${key}`) }),
	{}
);

/* manually trigger form submit / validation because button looks different */
const feedbackForm = ref<HTMLFormElement>();
const submitForm = (): void => {
	const node = feedbackForm?.value?.node;
	node.submit();
};

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (showFeedbackSuccess.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t("helpPage.thanksForFeedback")), 1))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (showFeedbackOptions.value)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("helpPage.wasQuestionAnswered")), 1),
              _createElementVNode("button", {
                type: "button",
                class: "mr-6 inline-flex items-center",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(sendFeedback)(_ctx.articleId, _ctx.articleName, 1)))
              }, [
                _createVNode(SvgIcon, {
                  "icon-name": "hook",
                  class: "mr-2 h-3.5 w-3.5"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("helpPage.yes")), 1)
              ]),
              _createElementVNode("button", {
                type: "button",
                class: "inline-flex items-center",
                onClick: negativeFeedback
              }, [
                _createVNode(SvgIcon, {
                  "icon-name": "close",
                  class: "mr-2 h-3.5 w-3.5"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("helpPage.no")), 1)
              ])
            ], 64))
          : (_openBlock(), _createBlock(_component_FormKit, {
              key: 1,
              ref_key: "feedbackForm",
              ref: feedbackForm,
              type: "form",
              config: { validationVisibility: 'submit' },
              actions: false,
              errors: [_ctx.$t(_unref(feedbackErrorText))],
              onSubmit: _cache[3] || (_cache[3] = ($event: any) => (_unref(sendFeedback)(_ctx.articleId, _ctx.articleName, 0, content.value, feedbackReason.value)))
            }, {
              default: _withCtx(() => [
                _createVNode(BaseDropdown, {
                  modelValue: selection.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selection).value = $event)),
                  label: _ctx.$t('helpPage.reasonLabel'),
                  name: _ctx.$t('helpPage.reasonInputName'),
                  options: _unref(feedbackOptions),
                  placeholder: _ctx.$t('helpPage.reasonPlaceholder'),
                  plugins: [_unref(useErrorTracking)().trackingPlugin],
                  validation: "required"
                }, null, 8, ["modelValue", "label", "name", "options", "placeholder", "plugins"]),
                _createVNode(_component_FormKit, {
                  modelValue: content.value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((content).value = $event)),
                  label: _ctx.$t('helpPage.inputLabel'),
                  plugins: [_unref(useErrorTracking)().trackingPlugin],
                  name: "textarea",
                  type: "textarea",
                  "outer-class": "$reset mb-8"
                }, null, 8, ["modelValue", "label", "plugins"]),
                _createVNode(_component_FormKit, {
                  type: "button",
                  label: _ctx.$t('helpPage.sendBtnLabel'),
                  onClick: submitForm
                }, null, 8, ["label"])
              ]),
              _: 1
            }, 8, ["errors"]))
      ], 64))
}
}

})