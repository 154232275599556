<template>
	<Transition
		:enter-active-class="`overflow-hidden transition-height duration-${durationTimeInMs}`"
		:leave-active-class="`overflow-hidden transition-height duration-${durationTimeInMs}`"
		@enter="enter"
		@after-enter="afterEnter"
		@leave="leave"
		@after-leave="onAfterLeave"
	>
		<slot />
	</Transition>
</template>

<script setup lang="ts">
import { durationTimeInMs } from "../../composables/use-transition-expand-duration";

const enter = (element: HTMLElement) => {
	element.style.height = "auto";
	const height = getComputedStyle(element).height;
	element.style.height = "0";

	getComputedStyle(element).height;

	requestAnimationFrame(() => {
		element.style.height = height;
	});
};

const afterEnter = (element: HTMLElement) => (element.style.height = "auto");

const leave = (element: HTMLElement): void => {
	element.style.height = getComputedStyle(element).height;

	getComputedStyle(element).height;

	requestAnimationFrame(() => {
		element.style.height = "0";
	});
};

const emit = defineEmits(["afterLeave"]);
const onAfterLeave = () => {
	emit("afterLeave");
};
</script>
