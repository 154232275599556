<script setup lang="ts">
import { ref } from "vue";
import SharedDialog from "../SharedDialog/SharedDialog.vue";
import Vue2Modal from "./Modal.vue";
import Vue3Modal from "./Vue3Modal.vue";

withDefaults(
	defineProps<{
		closeActionButton?: boolean;
		closeActionButtonText?: string;
		fullMaxHeight?: boolean;
		headline?: string;
		narrow?: boolean;
		noBodyPadding?: boolean;
		noScrollbar?: boolean;
		preventDismiss?: boolean;
		wide?: boolean;

		// props not used in SharedDialog
		ifShowIsOpen?: boolean; // needed in mf-checkout only
		isVue2?: boolean; // needed in mf-productlist only
		zIndex?: number; // needed in mf-productlist only
	}>(),
	// a boolean absent prop will be cast to `false`, see https://vuejs.org/guide/components/props.html#prop-validation
	{ ifShowIsOpen: undefined }
);
const emit = defineEmits<{
	(event: "closing", triggeredBy: string): void;
	(event: "closed"): void;
}>();

const isDialogSupported = "showModal" in document.createElement("dialog");

const dialogEl = ref<InstanceType<typeof SharedDialog> | null>(null);
const modalEl = ref<InstanceType<typeof Vue2Modal> | InstanceType<typeof Vue3Modal> | null>(null);
const isOpen = ref(false);

const open = () => {
	isDialogSupported ? dialogEl.value?.open() : (isOpen.value = true);
};
const close = () => {
	(isDialogSupported ? dialogEl.value : modalEl.value)?.close();
};

const onClosing = (triggeredBy: string) => {
	emit("closing", triggeredBy);
};
const onClosed = () => {
	emit("closed");
	if (!isDialogSupported) isOpen.value = false;
};

defineExpose({ open, close });
</script>

<template>
	<SharedDialog
		v-if="isDialogSupported"
		ref="dialogEl"
		:close-action-button="closeActionButton"
		:close-action-button-text="closeActionButtonText"
		:full-max-height="fullMaxHeight"
		:headline="headline"
		:narrow="narrow"
		:no-body-padding="noBodyPadding"
		:no-scrollbar="noScrollbar"
		:prevent-dismiss="preventDismiss"
		:wide="wide"
		@closing="onClosing"
		@closed="onClosed"
	>
		<slot />
		<template v-if="$slots.headerblock" #headerblock>
			<slot name="headerblock" />
		</template>
		<template #footer>
			<slot name="footer" />
		</template>
	</SharedDialog>

	<Vue2Modal
		v-else-if="isVue2 && isOpen"
		ref="modalEl"
		:close-action-button="closeActionButton"
		:close-action-button-text="closeActionButtonText"
		:full-max-height="fullMaxHeight"
		:headline="headline"
		:narrow="narrow"
		:no-body-padding="noBodyPadding"
		:no-scrollbar="noScrollbar"
		:wide="wide"
		:z-index="zIndex"
		@closing="onClosing"
		@closed="onClosed"
	>
		<slot />
		<template v-if="$slots.headerblock" #headerblock>
			<slot name="headerblock" />
		</template>
		<template #footer>
			<slot name="footer" />
		</template>
	</Vue2Modal>

	<Vue3Modal
		v-else-if="ifShowIsOpen !== undefined || isOpen"
		ref="modalEl"
		:close-action-button="closeActionButton"
		:close-action-button-text="closeActionButtonText"
		:full-max-height="fullMaxHeight"
		:headline="headline"
		:if-show-is-open="ifShowIsOpen"
		:narrow="narrow"
		:no-body-padding="noBodyPadding"
		:no-scrollbar="noScrollbar"
		:prevent-dismiss="preventDismiss"
		:wide="wide"
		@closing="onClosing"
		@closed="onClosed"
	>
		<slot />
		<template v-if="$slots.headerblock" #headerblock>
			<slot name="headerblock" />
		</template>
		<template #footer>
			<slot name="footer" />
		</template>
	</Vue3Modal>
</template>
