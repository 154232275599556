<template>
	<div>
		<HeadlineText>{{ $t("helpPage.headline") }}</HeadlineText>
		<div class="mb-20 mt-10">
			<template v-if="fetching && avoidFlickeringTimeout">
				<BasicSkeleton v-for="n in 5" :key="`skeleton-${n}`" class="mb-1 h-13" />
			</template>
			<div v-else-if="error">{{ $t("generalError") }}</div>
			<template v-else-if="data">
				<SharedExpandableGroup>
					<SharedExpandable
						v-for="(article, i) in shownItems"
						:id="article.id"
						:key="i"
						:title="article.name"
						:is-expanded="article.id === faqIdFromParam"
						@header-clicked="trackQuestionClicked(article.name)"
					>
						<p v-for="(element, j) in article.schema_elements" :key="j" class="mb-4" v-html="element.content"></p>
						<BaseFeedback :article-id="article.id" :article-name="article.name" />
					</SharedExpandable>
				</SharedExpandableGroup>
				<button v-if="showButton" type="button" class="ocm-body2 p-4 underline" @click="showMore">
					{{ $t("helpPage.showMore") }}
				</button>
			</template>
		</div>
		<p class="mb-4 font-semibold">{{ $t("helpPage.nothingSuitable") }}</p>
		<SharedButton
			@click="
				trackContinueToFormClicked();
				startContactForm(currentCollection?.id);
			"
			>{{ $t("helpPage.cta") }}</SharedButton
		>

		<i18n-t tag="p" class="mt-4" keypath="helpPage.ctaAlternative">
			<a type="tel" :href="`tel:${contactPhoneNumber()}`" class="font-medium"><br />{{ contactPhoneNumber() }}</a>
		</i18n-t>
	</div>
</template>

<script setup lang="ts">
import { Ref, ref, computed, onBeforeMount, onMounted } from "vue";
import SharedExpandable from "@SharedVueComponents/SharedExpandable/SharedExpandable.vue";
import SharedExpandableGroup from "@SharedVueComponents/SharedExpandable/SharedExpandableGroup.vue";
import SharedButton from "@SharedVueComponents/SharedButton.vue";
import BasicSkeleton from "@SharedVueComponents/BasicSkeleton/BasicSkeleton.vue";
import HeadlineText from "@/components/HeadlineText.vue";
import BaseFeedback from "@/components/BaseFeedback.vue";
import { startContactForm } from "@/composables/use-pageNavigation";
import { currentCollection } from "@/composables/use-polly";
import { useRouter } from "vue-router";
import { contactPhoneNumber } from "@/composables/use-contactPhoneNumber";
import { error, fetching, data, getFaqs } from "@/queries/faq.query";
import {
	trackShowMoreFAQsClicked,
	trackQuestionClicked,
	trackContinueToFormClicked,
} from "@/composables/use-trackingInteractions";
import { SELECTION_PAGE_NAME } from "@/types/routeName.type";
import { redirectLocalStorage } from "@/composables/use-redirect";
import { getCollectionFromId } from "@/queries/publications.query";
const avoidFlickeringTimeout: Ref<boolean> = ref(false);
const amountSlicedItems: Ref<number> = ref(5);

const router = useRouter();
const faqIdFromParam = ref();

onBeforeMount(async () => {
	await redirectLocalStorage();
	setFAQIdFromParams();
	setCollectionIdFromParams();

	setTimeout(() => {
		avoidFlickeringTimeout.value = true;
	}, 300);

	if (currentCollection.value && (data.value === undefined || !data.value[currentCollection.value.id])) {
		await getFaqs(currentCollection.value.id);
	}
	redirectNoFaqs();
	avoidFlickeringTimeout.value = false;
});

onMounted(async () => {
	if (faqIdFromParam.value) {
		showAllItems();
	}
});

const showAllItems = (): void => {
	amountSlicedItems.value = 0;
};

const setFAQIdFromParams = (): void => {
	const url = new URL(window.location.href);
	const params = new URLSearchParams(url.search);
	faqIdFromParam.value = params.get("faq");
};

const setCollectionIdFromParams = (): void => {
	const collectionId = router.currentRoute.value.params.collectionId;
	if (typeof collectionId === "string") currentCollection.value = getCollectionFromId(collectionId);
};

const redirectNoFaqs = (): void => {
	const hasValidData =
		currentCollection.value && data.value && data.value[currentCollection.value.id]?.collection?.articles.length;

	if (!hasValidData) {
		const backButtonNavigation = router.currentRoute.value.query.backNavigationNavigation;
		if (backButtonNavigation) {
			router.push({ name: SELECTION_PAGE_NAME });
		} else {
			startContactForm(currentCollection.value?.id, true);
		}
	}
};

const shownItems = computed(() => {
	if (!data.value || !currentCollection.value) return [];
	if (amountSlicedItems.value > 0)
		return data.value[currentCollection.value.id]?.collection?.articles.slice(0, amountSlicedItems.value) || [];
	return data.value[currentCollection.value.id]?.collection?.articles || [];
});

const showButton = computed(
	() =>
		data.value &&
		currentCollection.value &&
		amountSlicedItems.value > 0 &&
		amountSlicedItems.value !== data.value[currentCollection.value.id]?.collection?.articles.length &&
		(data.value[currentCollection.value.id]?.collection?.articles.length || 0) > 5
);

const showMore = (): void => {
	if (!data.value || !currentCollection.value) return;
	amountSlicedItems.value = data.value[currentCollection.value.id].collection?.articles.length || 999;
	trackShowMoreFAQsClicked();
};
</script>
