<template>
	<div>
		<HeadlineText>{{ $t("imageUploadPage.headline") }}</HeadlineText>
		<FormKit
			id="imageForm"
			ref="formRef"
			type="form"
			:config="{ validationVisibility: 'dirty' }"
			:submit-label="$t('next')"
			@submit="submitHandler"
		>
			<FormKit
				v-model="isShoe"
				name="isShoe"
				type="radio"
				:label="$t('imageUploadPage.shoeOptionsLabel')"
				validation="required"
				:options="[
					{ value: true, label: $t('imageUploadPage.shoeYes') },
					{ value: false, label: $t('imageUploadPage.shoeNo') },
				]"
			/>
			<FormKitImage
				v-model="articleImage"
				name="articleImage"
				:label="$t('imageUploadPage.articleLabel')"
				:plugins="[useErrorTracking().trackingPlugin]"
				validation="required"
			/>
			<FormKitImage
				v-model="defectImage"
				name="defectImage"
				:label="$t('imageUploadPage.defectLabel')"
				:plugins="[useErrorTracking().trackingPlugin]"
				validation="required"
			/>
			<TransitionExpand>
				<FormKitImage
					v-if="isShoe"
					v-model="soleImage"
					name="soleImage"
					:label="$t('imageUploadPage.soleLabel')"
					:plugins="[useErrorTracking().trackingPlugin]"
					validation="required"
				/>
			</TransitionExpand>
			<FormKitImage
				v-if="isClassicSite"
				v-model="receiptImage"
				name="receiptImage"
				:label="$t('imageUploadPage.receiptLabel')"
				validation="required"
			/>
			<FormKitImage
				v-model="optionalImage1"
				name="optionalImage1"
				:label="$t('imageUploadPage.anotherPictureOptional')"
				:plugins="[useErrorTracking().trackingPlugin]"
			/>
			<FormKitImage
				v-model="optionalImage2"
				name="optionalImage2"
				:label="$t('imageUploadPage.anotherPictureOptional')"
				:plugins="[useErrorTracking().trackingPlugin]"
			/>

			<div v-if="imageUploadSizeOverLimit" class="mb-10 flex items-center text-error">
				<SvgIcon class="mr-1 h-4 w-4" icon-name="info" />
				<span>
					{{ $t("imageUploadPage.fileSizeLimitInfo") }}
				</span>
			</div>
		</FormKit>
	</div>
</template>

<script setup lang="ts">
import { isClassicSite } from "@ocm/services/ocm-object";
import HeadlineText from "@/components/HeadlineText.vue";
import FormKitImage from "@/components/FormKitImage.vue";
import TransitionExpand from "@/components/TransitionExpand.vue";
import { imageUploadSizeOverLimit, invalidTypeFieldNames } from "@/composables/use-imageUpload";
import { useErrorTracking, trackImageSizeToLarge } from "@/composables/use-trackingErrors";
import SvgIcon from "@SharedVueComponents/SvgIcon/SvgIcon.vue";
import {
	isShoe,
	articleImage,
	defectImage,
	soleImage,
	receiptImage,
	optionalImage1,
	optionalImage2,
} from "@/composables/use-messageData";
import { goToNextFormStep } from "@/composables/use-pageNavigation";
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRerouteToValidStep } from "@/composables/use-formValidity";

const { t } = useI18n();
const formRef = ref();

onMounted(() => {
	useRerouteToValidStep(formRef);
});

const submitHandler = (): void => {
	if (imageUploadSizeOverLimit.value || invalidTypeFieldNames.value.size > 0) return;
	goToNextFormStep();
};

watch(imageUploadSizeOverLimit, () => {
	if (imageUploadSizeOverLimit.value) trackImageSizeToLarge(t);
});
</script>
