import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid auto-rows-[minmax(0,84px)] grid-cols-2 gap-4"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 2,
  class: "grid auto-rows-[minmax(0,84px)] grid-cols-2 gap-4"
}

import { computed, onBeforeMount } from "vue";
import BasicSkeleton from "@SharedVueComponents/BasicSkeleton/BasicSkeleton.vue";
import { startContactForm } from "@/composables/use-pageNavigation";
import { error, fetching, publicationData } from "@/queries/publications.query";
import BaseCard from "@/components/BaseCard.vue";
import { resultResponse } from "@/queries/search.query";


export default /*@__PURE__*/_defineComponent({
  __name: 'BasePublications',
  setup(__props) {

const redirectNoPublications = () => {
	if (!publicationData.value?.publication) {
		startContactForm("fallback");
	}
};

const isEmptySearchResult = computed(() => !resultResponse.value || resultResponse.value.length === 0);

onBeforeMount(() => {
	redirectNoPublications();
});

return (_ctx: any,_cache: any) => {
  return (_unref(fetching))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(8, (n) => {
          return _createVNode(BasicSkeleton, {
            key: `skeleton-${n}`,
            class: "rounded-lg"
          })
        }), 64))
      ]))
    : (_unref(error))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t("generalError")), 1))
      : (_unref(publicationData)?.publication?.collections && isEmptySearchResult.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(publicationData).publication.collections, (collection, i) => {
              return (_openBlock(), _createBlock(BaseCard, {
                key: i,
                collection: collection
              }, null, 8, ["collection"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
}
}

})