<template>
	<div v-if="showFeedbackSuccess" class="pt-4 font-medium">{{ $t("helpPage.thanksForFeedback") }}</div>
	<template v-else>
		<template v-if="showFeedbackOptions">
			<div class="mb-4 font-semibold">{{ $t("helpPage.wasQuestionAnswered") }}</div>
			<button type="button" class="mr-6 inline-flex items-center" @click="sendFeedback(articleId, articleName, 1)">
				<SvgIcon icon-name="hook" class="mr-2 h-3.5 w-3.5" />
				{{ $t("helpPage.yes") }}
			</button>
			<button type="button" class="inline-flex items-center" @click="negativeFeedback">
				<SvgIcon icon-name="close" class="mr-2 h-3.5 w-3.5" />
				{{ $t("helpPage.no") }}
			</button>
		</template>

		<FormKit
			v-else
			ref="feedbackForm"
			type="form"
			:config="{ validationVisibility: 'submit' }"
			:actions="false"
			:errors="[$t(feedbackErrorText)]"
			@submit="sendFeedback(articleId, articleName, 0, content, feedbackReason)"
		>
			<BaseDropdown
				v-model="selection"
				:label="$t('helpPage.reasonLabel')"
				:name="$t('helpPage.reasonInputName')"
				:options="feedbackOptions"
				:placeholder="$t('helpPage.reasonPlaceholder')"
				:plugins="[useErrorTracking().trackingPlugin]"
				validation="required"
			/>
			<FormKit
				v-model="content"
				:label="$t('helpPage.inputLabel')"
				:plugins="[useErrorTracking().trackingPlugin]"
				name="textarea"
				type="textarea"
				outer-class="$reset mb-8"
			/>
			<FormKit type="button" :label="$t('helpPage.sendBtnLabel')" @click="submitForm" />
		</FormKit>
	</template>
</template>

<script setup lang="ts">
import { computed, Ref, ref } from "vue";
import SvgIcon from "@SharedVueComponents/SvgIcon/SvgIcon.vue";
import { sendFeedback, showFeedbackSuccessIds, feedbackErrorText } from "@/queries/feedback.query";
import { trackNotHelpfulBtn } from "@/composables/use-trackingInteractions";
import { useErrorTracking } from "@/composables/use-trackingErrors";
import { useI18n } from "vue-i18n";
import BaseDropdown from "@/components/BaseDropdown.vue";

const props = defineProps<{
	articleId: string;
	articleName: string;
}>();

const showFeedbackSuccess = computed(
	(): boolean => !!showFeedbackSuccessIds.value.find((id: string) => id === props.articleId)
);

const feedbackReason = computed(() => optionTranslationKeys.indexOf(selection.value));
const content: Ref<string> = ref("");
const selection: Ref<string> = ref("");

const showFeedbackOptions: Ref<boolean> = ref(true);

const negativeFeedback = (): void => {
	showFeedbackOptions.value = false;
	trackNotHelpfulBtn(props.articleName);
};

const optionTranslationKeys = ["unclear", "containsErrors", "wrongQuestion", "stillNeedHelp"];

const { t } = useI18n();
const feedbackOptions = optionTranslationKeys.reduce(
	(acc, key) => ({ ...acc, [key]: t(`helpPage.reasons.${key}`) }),
	{}
);

/* manually trigger form submit / validation because button looks different */
const feedbackForm = ref<HTMLFormElement>();
const submitForm = (): void => {
	const node = feedbackForm?.value?.node;
	node.submit();
};
</script>

<style scoped>
/* no idea how to do this in Tailwind */
/* text-error */
.formkit-outer[data-invalid] ~ .label {
	color: var(--color-error);
}

.formkit-outer:hover ~ .label {
	color: var(--color-black);
}
</style>
