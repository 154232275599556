<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from "vue";
import CloseButton from "../CloseButton/CloseButton.vue";
import SharedButton from "../SharedButton.vue";
import { animationDurationMs } from "../../../services/services.modal";

withDefaults(
	defineProps<{
		closeActionButton?: boolean;
		closeActionButtonText?: string;
		fullMaxHeight?: boolean;
		headline?: string;
		narrow?: boolean;
		noBodyPadding?: boolean;
		noScrollbar?: boolean;
		preventDismiss?: boolean;
		wide?: boolean;
	}>(),
	{
		closeActionButtonText: "Ok",
	}
);
const emit = defineEmits<{
	(event: "closing", triggeredBy: string): void;
	(event: "closed"): void;
}>();

const uuid = (Math.random() + 1).toString(36).substring(2);

const slideIn = ref(false);
const dialogEl = ref<HTMLDialogElement | null>(null);
const open = () => {
	slideIn.value = true;
	dialogEl.value?.showModal();
	dialogEl.value?.focus();
};
const close = (triggeredBy = "") => {
	slideIn.value = false;
	emit("closing", triggeredBy);
};

const onAnimationEnd = ({ animationName }: AnimationEvent) => {
	if (animationName !== "dialogSlideOut") return;

	dialogEl.value?.close();
	emit("closed");
};
onMounted(() => {
	dialogEl.value?.addEventListener("animationend", onAnimationEnd);
	dialogEl.value?.addEventListener("animationcancel", onAnimationEnd);
});
onBeforeUnmount(() => {
	dialogEl.value?.removeEventListener("animationend", onAnimationEnd);
	dialogEl.value?.removeEventListener("animationcancel", onAnimationEnd);
});

defineExpose({ open, close });
</script>

<template>
	<!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
	<dialog
		ref="dialogEl"
		class="shared-dialog tw"
		:class="[
			slideIn ? 'dialog-animate-in' : 'dialog-animate-out',
			{
				'is-narrow': narrow,
				'is-wide': wide,
				'max-h-full': fullMaxHeight,
			},
		]"
		:style="{ '--animation-duration': animationDurationMs }"
		:aria-labelledby="headline ? uuid : undefined"
		tabindex="-1"
		@cancel.prevent="close('escapeKey')"
		@click.self.stop="!preventDismiss && close('overlay')"
	>
		<div class="flex flex-col overflow-y-auto">
			<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
			<CloseButton
				v-if="!preventDismiss"
				class="right-4 top-4"
				data-test-dialog-close-button
				@click.native="close('closeButton')"
			/>
			<!-- eslint-enable vue/no-deprecated-v-on-native-modifier -->
			<h2
				v-if="headline"
				:id="uuid"
				class="ocm-headline2 border-b-2 border-b-gray-30 pb-3.5 pl-4 pr-16 pt-4 tablet-down:hyphens-auto tablet-down:break-words"
				data-test-modal-headline
			>
				{{ headline }}
			</h2>

			<div
				class="pb-4"
				:class="[
					noScrollbar ? 'flex flex-col overflow-y-auto' : 'o-scrollbar overscroll-contain',
					{
						'lg-down:pb-9': !closeActionButton,
						'px-4 pt-6': !noBodyPadding,
					},
				]"
			>
				<div v-if="$slots.headerblock" class="-mt-5 bg-gray-20 px-4 py-9" :class="{ '-mx-4': !noBodyPadding }">
					<slot name="headerblock" />
				</div>
				<slot />
			</div>

			<div v-if="closeActionButton" class="flex justify-end px-4 pb-4">
				<slot name="footer" />
				<!-- eslint-disable-next-line vue/no-deprecated-v-on-native-modifier -->
				<SharedButton class="min-w-[calc(var(--spacing-base-unit)*25)]" @click.native="close('actionButton')">
					{{ closeActionButtonText }}
				</SharedButton>
			</div>
		</div>
	</dialog>
</template>

<style lang="scss">
.shared-dialog,
.shared-dialog::backdrop {
	animation-duration: calc(var(--animation-duration, 320) * 1ms);
	animation-timing-function: ease;
	animation-fill-mode: both;
}

.shared-dialog {
	background-color: var(--color-white);
	color: var(--color-black-primary);
	font-weight: initial;
	text-align: initial;
	border: unset;
	padding: unset;
	width: 100%;
	max-width: 700px;
	max-height: 80%;

	&[open] {
		display: flex;
		flex-direction: column;
	}

	@media not all and (min-width: 64em) {
		top: unset;
	}

	&::backdrop {
		background-color: var(--color-dimmer);
	}

	&.is-wide {
		max-width: 900px;
	}

	&.is-narrow {
		max-width: 500px;
	}
}

@keyframes dialogSlideIn {
	from {
		transform: translateY(100%);
	}
	to {
		transform: translateY(0);
	}
}
@keyframes dialogSlideOut {
	from {
		transform: translateY(0);
	}
	to {
		transform: translateY(100%);
	}
}

@media (min-width: 64em) {
	@keyframes dialogSlideIn {
		from {
			transform: translateY(calc(50vh + 50%));
		}
		to {
			transform: translateY(0);
		}
	}
	@keyframes dialogSlideOut {
		from {
			transform: translateY(0);
		}
		to {
			transform: translateY(calc(50vh + 50%));
		}
	}
}

@keyframes dialogFadeIn {
	from {
		background-color: transparent;
	}
	to {
		background-color: var(--color-dimmer);
	}
}
@keyframes dialogFadeOut {
	from {
		background-color: var(--color-dimmer);
	}
	to {
		background-color: transparent;
	}
}

.dialog-animate-in {
	animation-name: dialogSlideIn;

	&::backdrop {
		animation-name: dialogFadeIn;
	}
}

.dialog-animate-out {
	animation-name: dialogSlideOut;

	&::backdrop {
		animation-name: dialogFadeOut;
	}
}
</style>
