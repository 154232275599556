import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-4 text-xl font-semibold" }
const _hoisted_2 = { class: "mb-20" }

import { startContactForm } from "@/composables/use-pageNavigation";
import { redirectLocalStorage, triggerReauthOrLogin } from "@/composables/use-redirect";
import router from "@/router";
import SharedButton from "@SharedVueComponents/SharedButton.vue";
import { onBeforeMount } from "vue";
import { hasUserPermission } from "@/composables/use-restrictions";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginInfoPage',
  setup(__props) {

onBeforeMount(async () => {
	await redirectLocalStorage();
	redirectToFormIfPermissionsAreValid();
});

async function redirectToFormIfPermissionsAreValid(): Promise<void> {
	const collectionIdParams = router.currentRoute.value.params.collectionId;
	const isCollectionObject = typeof collectionIdParams === "object";
	const collectionIdParam = isCollectionObject ? collectionIdParams[0] : collectionIdParams;
	if (await hasUserPermission()) startContactForm(collectionIdParam, true);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t("LoginInfoPage.headline")), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("LoginInfoPage.helpText")), 1),
    _createVNode(SharedButton, {
      primary: "",
      class: "mb-4 w-3/4 lg-down:w-full",
      onClick: _unref(triggerReauthOrLogin)
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("LoginInfoPage.cta")), 1)
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}
}

})