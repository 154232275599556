import { returnEmailObject } from "./use-messageData";
import { ref, Ref } from "vue";
import { $axios } from "@ocm/axios";
import { authRequired } from "./use-restrictions";
import { THANK_YOU_PAGE_NAME } from "@/types/routeName.type";
import router from "@/router";

export const mailErrorText: Ref<string> = ref("");

export const sendMail = async (token: string): Promise<void> => {
	mailErrorText.value = "";
	await sendMailTo(token);
	if (mailErrorText.value) return;
	router.push({ name: THANK_YOU_PAGE_NAME });
};

const sendMailTo = async (token: string): Promise<string | undefined> => {
	const mailObject = await returnEmailObject();
	const url = authRequired.value ? "/rest/contactcenter/sendmailwithattachments" : "/rest/contactcenter/sendmail";
	const json = JSON.stringify({ ...mailObject });
	try {
		return await $axios.post(url, json, {
			headers: {
				"content-type": "application/json",
				"g-recaptcha-response": token,
			},
		});
	} catch (err) {
		mailErrorText.value = "generalError";
		console.log(err);
	}
};
