import type { ComposerTranslation } from "vue-i18n";
import { CustomerData } from "@/types/customerData.type";
import { email, emailDisabled, firstName, lastName, salutation } from "@/composables/use-messageData";
import { getClientSideAuthLevel, isRoleFullAccess, isRoleRememberMe } from "@/services/authlevel";
import { customerData, getCustomerData } from "@/queries/customerData.query";

const MrTitleCode = "mr";
const MsTitleCode = "mrs";

export let wasAutofillExecuted = false;

export async function getAndAutofillContactData(translation: ComposerTranslation) {
	if (wasAutofillExecuted) return;
	const localAuthLevel = getClientSideAuthLevel();
	if (!customerData.value && hasAuthLevelForAutofill(localAuthLevel)) {
		await getCustomerData();
	}
	if (customerData.value) autofillInfos(customerData.value, translation);
}

export function autofillInfos(customerData: CustomerData, translation: ComposerTranslation) {
	firstName.value = customerData.firstName;
	lastName.value = customerData.lastName;
	email.value = customerData.email;
	emailDisabled.value = true;
	if (customerData.titleCode === MrTitleCode) salutation.value = translation("personalDataPage.mr");
	if (customerData.titleCode === MsTitleCode) salutation.value = translation("personalDataPage.mrs");
	wasAutofillExecuted = true;
}

function hasAuthLevelForAutofill(authLevel: AuthLevelType | undefined) {
	return !!authLevel && (isRoleRememberMe(authLevel) || isRoleFullAccess(authLevel));
}
