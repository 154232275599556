export const ocmTextInput = {
	type: "input",
	props: ["inputType"],
	schema: [
		{
			$el: "div",
			attrs: {
				class: "$classes.outer",
				"data-type": "$type",
				"data-multiple": "$attrs.multiple",
				"data-disabled": "$disabled || undefined",
				"data-complete": "$state.complete || undefined",
				"data-invalid": "$state.valid === false && $state.validationVisible || undefined",
				"data-errors": "$state.errors || undefined",
				"data-submitted": "$state.submitted || undefined",
			},
			children: [
				{
					$el: "div",
					attrs: {
						class: "$classes.wrapper",
					},
					children: [
						{
							$el: "input",
							bind: "$attrs",
							attrs: {
								placeholder: " ", // required for correct styling behavior
								type: "$inputType",
								disabled: "$disabled",
								class: "$classes.input",
								name: "$node.name",
								onInput: "$handlers.DOMInput",
								onBlur: "$handlers.blur",
								value: "$_value",
								id: "$id",
							},
						},
						{
							$el: "label",
							if: "$label",
							attrs: {
								class: "$classes.label",
							},
							children: "$label",
						},
						{
							$el: "div",
							if: "$help",
							attrs: {
								class: "$classes.help",
							},
							children: "$help",
						},
						{
							$el: "ul",
							attrs: {
								class: "$classes.messages",
							},
							children: [
								{
									$el: "li",
									for: ["message", "$messages"],
									attrs: {
										class: "$classes.message",
									},
									children: "$message.value",
								},
							],
						},
					],
				},
			],
		},
	],
};