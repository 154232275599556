<template>
	<div>
		<p class="mb-4 text-lg font-semibold">
			{{ $t("selectionPage.headline") }}
		</p>

		<FaqSearch :search-param="searchParam" />
		<BaseAnnouncement />
		<BasePublications />
	</div>
</template>

<script setup lang="ts">
import FaqSearch from "@/components/FaqSearch.vue";
import BaseAnnouncement from "@/components/BaseAnnouncement.vue";
import BasePublications from "@/components/BasePublications.vue";
import { currentCollection } from "@/composables/use-polly";
import { computed, onBeforeMount } from "vue";
import router from "@/router";

const searchParam = computed(() => {
	const searchParamLocal = router.currentRoute.value.query.search;
	if (searchParamLocal && typeof searchParamLocal === "object") return searchParamLocal[0];
	return searchParamLocal;
});

onBeforeMount(() => {
	currentCollection.value = undefined;
});
</script>
