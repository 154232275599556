import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-4 mb-6 text-xl font-semibold"
}

import { ref } from "vue";
import { currentCollection } from "@/composables/use-polly";
import "@/composables/use-trackingVirtualPageViews";
import BackButton from "@/components/BackButton.vue";
import TransitionSection from "@/components/TransitionSection.vue";
import { isCurrentPage } from "@/composables/use-pageNavigation";
import { THANK_YOU_PAGE_NAME, LOGIN_INFO_PAGE_NAME, SELECTION_PAGE_NAME } from "@/types/routeName.type";
import InputStepsProgress from "@/components/InputStepsProgress.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactLayout',
  setup(__props) {

const contactRef = ref<HTMLDivElement>();

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    id: "contact",
    ref_key: "contactRef",
    ref: contactRef,
    class: "tw relative m-auto max-w-2xl px-4 pb-20"
  }, [
    _createVNode(BackButton),
    _createVNode(TransitionSection, null, {
      default: _withCtx(() => [
        (!_unref(isCurrentPage)(_unref(THANK_YOU_PAGE_NAME)) && !_unref(isCurrentPage)(_unref(LOGIN_INFO_PAGE_NAME)))
          ? (_openBlock(), _createElementBlock("h2", _hoisted_1, _toDisplayString(_unref(isCurrentPage)(_unref(SELECTION_PAGE_NAME)) ? _ctx.$t("headline") : _unref(currentCollection)?.name), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(InputStepsProgress),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        _createVNode(TransitionSection, null, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    })
  ], 512))
}
}

})