import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-4 text-lg font-semibold" }

import FaqSearch from "@/components/FaqSearch.vue";
import BaseAnnouncement from "@/components/BaseAnnouncement.vue";
import BasePublications from "@/components/BasePublications.vue";
import { currentCollection } from "@/composables/use-polly";
import { computed, onBeforeMount } from "vue";
import router from "@/router";


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectionPage',
  setup(__props) {

const searchParam = computed(() => {
	const searchParamLocal = router.currentRoute.value.query.search;
	if (searchParamLocal && typeof searchParamLocal === "object") return searchParamLocal[0];
	return searchParamLocal;
});

onBeforeMount(() => {
	currentCollection.value = undefined;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t("selectionPage.headline")), 1),
    _createVNode(FaqSearch, { "search-param": searchParam.value }, null, 8, ["search-param"]),
    _createVNode(BaseAnnouncement),
    _createVNode(BasePublications)
  ]))
}
}

})