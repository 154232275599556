import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]

import { onBeforeMount } from "vue";
import InfoBox from "./InfoBox.vue";
import { data, getAnnouncements } from "@/queries/announcements.query";


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseAnnouncement',
  setup(__props) {

onBeforeMount(async () => {
	if (!data.value) {
		await getAnnouncements();
	}
});

return (_ctx: any,_cache: any) => {
  return (_unref(data)?.publication?.announcement?.schema_elements?.length)
    ? (_openBlock(), _createBlock(InfoBox, {
        key: 0,
        class: "mb-6"
      }, {
        default: _withCtx(() => [
          (_unref(data).publication.announcement.name)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "mb-2 font-medium",
                innerHTML: _unref(data).publication.announcement.name
              }, null, 8, _hoisted_1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data).publication.announcement.schema_elements, (content, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: i,
              innerHTML: content.content
            }, null, 8, _hoisted_2))
          }), 128))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})