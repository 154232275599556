import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "mb-10 flex items-center text-error"
}

import { isClassicSite } from "@ocm/services/ocm-object";
import HeadlineText from "@/components/HeadlineText.vue";
import FormKitImage from "@/components/FormKitImage.vue";
import TransitionExpand from "@/components/TransitionExpand.vue";
import { imageUploadSizeOverLimit, invalidTypeFieldNames } from "@/composables/use-imageUpload";
import { useErrorTracking, trackImageSizeToLarge } from "@/composables/use-trackingErrors";
import SvgIcon from "@SharedVueComponents/SvgIcon/SvgIcon.vue";
import {
	isShoe,
	articleImage,
	defectImage,
	soleImage,
	receiptImage,
	optionalImage1,
	optionalImage2,
} from "@/composables/use-messageData";
import { goToNextFormStep } from "@/composables/use-pageNavigation";
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRerouteToValidStep } from "@/composables/use-formValidity";


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageUploadPage',
  setup(__props) {

const { t } = useI18n();
const formRef = ref();

onMounted(() => {
	useRerouteToValidStep(formRef);
});

const submitHandler = (): void => {
	if (imageUploadSizeOverLimit.value || invalidTypeFieldNames.value.size > 0) return;
	goToNextFormStep();
};

watch(imageUploadSizeOverLimit, () => {
	if (imageUploadSizeOverLimit.value) trackImageSizeToLarge(t);
});

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(HeadlineText, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("imageUploadPage.headline")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_FormKit, {
      id: "imageForm",
      ref_key: "formRef",
      ref: formRef,
      type: "form",
      config: { validationVisibility: 'dirty' },
      "submit-label": _ctx.$t('next'),
      onSubmit: submitHandler
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FormKit, {
          modelValue: _unref(isShoe),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(isShoe) ? (isShoe).value = $event : null)),
          name: "isShoe",
          type: "radio",
          label: _ctx.$t('imageUploadPage.shoeOptionsLabel'),
          validation: "required",
          options: [
					{ value: true, label: _ctx.$t('imageUploadPage.shoeYes') },
					{ value: false, label: _ctx.$t('imageUploadPage.shoeNo') },
				]
        }, null, 8, ["modelValue", "label", "options"]),
        _createVNode(FormKitImage, {
          modelValue: _unref(articleImage),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(articleImage) ? (articleImage).value = $event : null)),
          name: "articleImage",
          label: _ctx.$t('imageUploadPage.articleLabel'),
          plugins: [_unref(useErrorTracking)().trackingPlugin],
          validation: "required"
        }, null, 8, ["modelValue", "label", "plugins"]),
        _createVNode(FormKitImage, {
          modelValue: _unref(defectImage),
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(defectImage) ? (defectImage).value = $event : null)),
          name: "defectImage",
          label: _ctx.$t('imageUploadPage.defectLabel'),
          plugins: [_unref(useErrorTracking)().trackingPlugin],
          validation: "required"
        }, null, 8, ["modelValue", "label", "plugins"]),
        _createVNode(TransitionExpand, null, {
          default: _withCtx(() => [
            (_unref(isShoe))
              ? (_openBlock(), _createBlock(FormKitImage, {
                  key: 0,
                  modelValue: _unref(soleImage),
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(soleImage) ? (soleImage).value = $event : null)),
                  name: "soleImage",
                  label: _ctx.$t('imageUploadPage.soleLabel'),
                  plugins: [_unref(useErrorTracking)().trackingPlugin],
                  validation: "required"
                }, null, 8, ["modelValue", "label", "plugins"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (_unref(isClassicSite))
          ? (_openBlock(), _createBlock(FormKitImage, {
              key: 0,
              modelValue: _unref(receiptImage),
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(receiptImage) ? (receiptImage).value = $event : null)),
              name: "receiptImage",
              label: _ctx.$t('imageUploadPage.receiptLabel'),
              validation: "required"
            }, null, 8, ["modelValue", "label"]))
          : _createCommentVNode("", true),
        _createVNode(FormKitImage, {
          modelValue: _unref(optionalImage1),
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(optionalImage1) ? (optionalImage1).value = $event : null)),
          name: "optionalImage1",
          label: _ctx.$t('imageUploadPage.anotherPictureOptional'),
          plugins: [_unref(useErrorTracking)().trackingPlugin]
        }, null, 8, ["modelValue", "label", "plugins"]),
        _createVNode(FormKitImage, {
          modelValue: _unref(optionalImage2),
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_isRef(optionalImage2) ? (optionalImage2).value = $event : null)),
          name: "optionalImage2",
          label: _ctx.$t('imageUploadPage.anotherPictureOptional'),
          plugins: [_unref(useErrorTracking)().trackingPlugin]
        }, null, 8, ["modelValue", "label", "plugins"]),
        (_unref(imageUploadSizeOverLimit))
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(SvgIcon, {
                class: "mr-1 h-4 w-4",
                "icon-name": "info"
              }),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("imageUploadPage.fileSizeLimitInfo")), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["submit-label"])
  ]))
}
}

})