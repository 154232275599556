<template>
	<div>
		<HeadlineText>{{ $t("orderPage.headline") }}</HeadlineText>
		<FormKit
			ref="formRef"
			type="form"
			:submit-label="$t('next')"
			:config="{ validationVisibility: 'submit' }"
			@submit="goToNextFormStep()"
		>
			<FormKit
				v-model="orderNumber"
				name="Bestellnummer"
				label="Bestellnummer"
				type="ocmTextInput"
				input-type="number"
				:plugins="[useErrorTracking().trackingPlugin]"
				validation="required|matches:/^[2-4][0-9]{7}$/"
				:validation-messages="{
					matches: $t('orderPage.lengthErrorMessage'),
				}"
			/>
			<div class="mb-20 grid grid-cols-[min-content,1fr] items-center gap-x-2">
				<SvgIcon icon-name="info" class="h-4.5 w-4.5" />
				<InfoModal :headline="$t('orderPage.helpHeadline')">
					<template #trigger>{{ $t("orderPage.helpHeadline") }}</template>
					{{ $t("orderPage.helpText") }}
				</InfoModal>
			</div>
		</FormKit>
	</div>
</template>

<script setup lang="ts">
import SvgIcon from "@SharedVueComponents/SvgIcon/SvgIcon.vue";
import InfoModal from "@SharedVueComponents/Modal/InfoModal.vue";
import HeadlineText from "@/components/HeadlineText.vue";
import { goToNextFormStep } from "@/composables/use-pageNavigation";
import { useErrorTracking } from "@/composables/use-trackingErrors";
import { orderNumber } from "@/composables/use-messageData";
import { onMounted, ref } from "vue";
import { useRerouteToValidStep } from "@/composables/use-formValidity";

const formRef = ref();

onMounted(() => {
	useRerouteToValidStep(formRef);
});
</script>
