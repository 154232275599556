<template>
	<svg
		class="svg-icon"
		:class="`icon-${iconName}`"
		:aria-hidden="!title ? 'true' : undefined"
		:aria-labelledby="title ? titleId : undefined"
	>
		<title v-if="title" :id="titleId">{{ title }}</title>
		<use :xlink:href="`#icon-${iconName}`" :href="`#icon-${iconName}`" />
	</svg>
</template>

<script setup lang="ts">
defineProps<{
	iconName: string;
	title?: string;
}>();

const titleId = (Math.random() + 1).toString(36).substring(2);
</script>
