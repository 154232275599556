import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { currentCollection } from "@/composables/use-polly";
import { HELP_PAGE_NAME } from "@/types/routeName.type";
import { Collection } from "@/types/publications.type";
import { RouterLink } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseCard',
  props: {
    collection: {}
  },
  setup(__props: any) {


const setCollectionState = (collection: Collection) => {
	currentCollection.value = collection;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(RouterLink), {
    id: _ctx.collection.id,
    to: { name: _unref(HELP_PAGE_NAME), params: { collectionId: _ctx.collection.id } },
    class: "grid place-content-center rounded-lg bg-white p-4 text-center text-sm shadow-contactCards hover:bg-gray-40 focus-visible:bg-gray-40",
    onClickOnce: _cache[0] || (_cache[0] = ($event: any) => (setCollectionState(_ctx.collection)))
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.collection.name), 1)
    ]),
    _: 1
  }, 8, ["id", "to"]))
}
}

})