<template>
	<Teleport to="body">
		<!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
		<div
			v-show="ifShowIsOpen"
			class="tw fallback-modal modalOverlay animated fixed left-0 top-0 z-[999] h-full w-screen items-end justify-center lg:items-center"
			:class="[$attrs.class, slideIn ? 'modalFadeIn' : 'modalFadeOut']"
			:style="{ '--animationDuration': `${animationDurationMs}ms` }"
			@click.self.stop="!preventDismiss && close('overlay')"
		>
			<div
				class="modal animated relative flex w-full flex-col bg-white text-black-primary"
				:class="[
					slideIn ? 'modalSlideInUp' : 'modalSlideOutDown',
					{
						'is-narrow': narrow,
						'is-wide': wide,
						'max-h-full': fullMaxHeight,
					},
				]"
				:role="$attrs.role ?? 'dialog'"
				@click.stop
			>
				<CloseButton
					v-if="!preventDismiss"
					class="right-4 top-4"
					data-test-modal-close-button
					@click="close('closeButton')"
				/>
				<h2
					v-if="headline"
					class="ocm-headline2 border-b-2 border-b-gray-30 pb-3.5 pl-4 pr-16 pt-4 tablet-down:hyphens-auto tablet-down:break-words"
					data-test-modal-headline
				>
					{{ headline }}
				</h2>

				<div
					class="pb-4"
					:class="[
						noScrollbar ? 'flex flex-col overflow-y-hidden' : 'o-scrollbar overscroll-contain',
						{
							'lg-down:pb-9': !closeActionButton,
							'px-4 pt-6': !noBodyPadding,
						},
					]"
				>
					<div v-if="$slots.headerblock" class="-mt-5 bg-gray-20 px-4 py-9" :class="{ '-mx-4': !noBodyPadding }">
						<slot name="headerblock" />
					</div>
					<slot />
				</div>

				<div v-if="closeActionButton" class="flex justify-end px-4 pb-4">
					<slot name="footer" />
					<SharedButton class="min-w-[calc(var(--spacing-base-unit)*25)]" @click="close('actionButton')">
						{{ closeActionButtonText }}
					</SharedButton>
				</div>
			</div>
		</div>
	</Teleport>
</template>

<script setup lang="ts">
import { ref, watch, onBeforeUnmount } from "vue";
import CloseButton from "../CloseButton/CloseButton.vue";
import SharedButton from "../SharedButton.vue";
import { animationDurationMs } from "../../../services/services.modal";

const props = withDefaults(
	defineProps<{
		ifShowIsOpen?: boolean;
		wide?: boolean;
		fullMaxHeight?: boolean;
		narrow?: boolean;
		preventDismiss?: boolean;
		closeActionButton?: boolean;
		closeActionButtonText?: string;
		headline?: string;
		noScrollbar?: boolean;
		noBodyPadding?: boolean;
	}>(),
	{ closeActionButtonText: "Ok", ifShowIsOpen: true }
);
const emit = defineEmits<{
	(event: "closing", triggeredBy: string): void;
	(event: "closed"): void;
}>();

const slideIn = ref(true);
watch(
	() => props.ifShowIsOpen,
	(newVal) => (slideIn.value = newVal)
);

const timeout = ref<number>();
onBeforeUnmount(() => {
	clearTimeout(timeout.value);
});

const close = (triggeredBy = "") => {
	slideIn.value = false;
	emit("closing", triggeredBy);
	timeout.value = window.setTimeout(() => {
		emit("closed");
	}, animationDurationMs);
};
defineExpose({ close });
</script>

<style lang="scss" scoped>
@import "../../../css/responsive";
@import "../../../css/variables";

.modalOverlay {
	display: flex; // 🔺 must not be set with Tailwind `flex` because then `v-show` doesn't work

	.modal {
		max-width: 700px;
		max-height: 80%;

		&.is-wide {
			max-width: 900px;
		}

		&.is-narrow {
			max-width: 500px;
		}
	}
}

.animated {
	animation-duration: var(--animationDuration);
	animation-timing-function: ease;
	animation-fill-mode: both;
}

@keyframes modalSlideInUp {
	from {
		transform: translateY(100%);
	}
	to {
		transform: translateY(0);
	}
}
@keyframes modalSlideOutDown {
	from {
		transform: translateY(0);
	}
	to {
		transform: translateY(100%);
	}
}

@include media-query(l-up) {
	@keyframes modalSlideInUp {
		from {
			transform: translateY(calc(50vh + 50%));
		}
		to {
			transform: translateY(0);
		}
	}
	@keyframes modalSlideOutDown {
		from {
			transform: translateY(0);
		}
		to {
			transform: translateY(calc(50vh + 50%));
		}
	}
}

.modalSlideInUp {
	animation-name: modalSlideInUp;
}

.modalSlideOutDown {
	animation-name: modalSlideOutDown;
}

@keyframes modalFadeIn {
	from {
		background-color: transparent;
	}
	to {
		background-color: var(--color-dimmer);
	}
}
@keyframes modalFadeOut {
	from {
		background-color: var(--color-dimmer);
	}
	to {
		background-color: transparent;
	}
}

.modalFadeIn {
	animation-name: modalFadeIn;
}

.modalFadeOut {
	animation-name: modalFadeOut;
}
</style>
