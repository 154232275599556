import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "mb-20 mt-10" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "mb-4 font-semibold" }
const _hoisted_5 = ["href"]

import { Ref, ref, computed, onBeforeMount, onMounted } from "vue";
import SharedExpandable from "@SharedVueComponents/SharedExpandable/SharedExpandable.vue";
import SharedExpandableGroup from "@SharedVueComponents/SharedExpandable/SharedExpandableGroup.vue";
import SharedButton from "@SharedVueComponents/SharedButton.vue";
import BasicSkeleton from "@SharedVueComponents/BasicSkeleton/BasicSkeleton.vue";
import HeadlineText from "@/components/HeadlineText.vue";
import BaseFeedback from "@/components/BaseFeedback.vue";
import { startContactForm } from "@/composables/use-pageNavigation";
import { currentCollection } from "@/composables/use-polly";
import { useRouter } from "vue-router";
import { contactPhoneNumber } from "@/composables/use-contactPhoneNumber";
import { error, fetching, data, getFaqs } from "@/queries/faq.query";
import {
	trackShowMoreFAQsClicked,
	trackQuestionClicked,
	trackContinueToFormClicked,
} from "@/composables/use-trackingInteractions";
import { SELECTION_PAGE_NAME } from "@/types/routeName.type";
import { redirectLocalStorage } from "@/composables/use-redirect";
import { getCollectionFromId } from "@/queries/publications.query";

export default /*@__PURE__*/_defineComponent({
  __name: 'HelpPage',
  setup(__props) {

const avoidFlickeringTimeout: Ref<boolean> = ref(false);
const amountSlicedItems: Ref<number> = ref(5);

const router = useRouter();
const faqIdFromParam = ref();

onBeforeMount(async () => {
	await redirectLocalStorage();
	setFAQIdFromParams();
	setCollectionIdFromParams();

	setTimeout(() => {
		avoidFlickeringTimeout.value = true;
	}, 300);

	if (currentCollection.value && (data.value === undefined || !data.value[currentCollection.value.id])) {
		await getFaqs(currentCollection.value.id);
	}
	redirectNoFaqs();
	avoidFlickeringTimeout.value = false;
});

onMounted(async () => {
	if (faqIdFromParam.value) {
		showAllItems();
	}
});

const showAllItems = (): void => {
	amountSlicedItems.value = 0;
};

const setFAQIdFromParams = (): void => {
	const url = new URL(window.location.href);
	const params = new URLSearchParams(url.search);
	faqIdFromParam.value = params.get("faq");
};

const setCollectionIdFromParams = (): void => {
	const collectionId = router.currentRoute.value.params.collectionId;
	if (typeof collectionId === "string") currentCollection.value = getCollectionFromId(collectionId);
};

const redirectNoFaqs = (): void => {
	const hasValidData =
		currentCollection.value && data.value && data.value[currentCollection.value.id]?.collection?.articles.length;

	if (!hasValidData) {
		const backButtonNavigation = router.currentRoute.value.query.backNavigationNavigation;
		if (backButtonNavigation) {
			router.push({ name: SELECTION_PAGE_NAME });
		} else {
			startContactForm(currentCollection.value?.id, true);
		}
	}
};

const shownItems = computed(() => {
	if (!data.value || !currentCollection.value) return [];
	if (amountSlicedItems.value > 0)
		return data.value[currentCollection.value.id]?.collection?.articles.slice(0, amountSlicedItems.value) || [];
	return data.value[currentCollection.value.id]?.collection?.articles || [];
});

const showButton = computed(
	() =>
		data.value &&
		currentCollection.value &&
		amountSlicedItems.value > 0 &&
		amountSlicedItems.value !== data.value[currentCollection.value.id]?.collection?.articles.length &&
		(data.value[currentCollection.value.id]?.collection?.articles.length || 0) > 5
);

const showMore = (): void => {
	if (!data.value || !currentCollection.value) return;
	amountSlicedItems.value = data.value[currentCollection.value.id].collection?.articles.length || 999;
	trackShowMoreFAQsClicked();
};

return (_ctx: any,_cache: any) => {
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(HeadlineText, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("helpPage.headline")), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      (_unref(fetching) && avoidFlickeringTimeout.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(5, (n) => {
            return _createVNode(BasicSkeleton, {
              key: `skeleton-${n}`,
              class: "mb-1 h-13"
            })
          }), 64))
        : (_unref(error))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t("generalError")), 1))
          : (_unref(data))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createVNode(SharedExpandableGroup, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(shownItems.value, (article, i) => {
                      return (_openBlock(), _createBlock(SharedExpandable, {
                        id: article.id,
                        key: i,
                        title: article.name,
                        "is-expanded": article.id === faqIdFromParam.value,
                        onHeaderClicked: ($event: any) => (_unref(trackQuestionClicked)(article.name))
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(article.schema_elements, (element, j) => {
                            return (_openBlock(), _createElementBlock("p", {
                              key: j,
                              class: "mb-4",
                              innerHTML: element.content
                            }, null, 8, _hoisted_3))
                          }), 128)),
                          _createVNode(BaseFeedback, {
                            "article-id": article.id,
                            "article-name": article.name
                          }, null, 8, ["article-id", "article-name"])
                        ]),
                        _: 2
                      }, 1032, ["id", "title", "is-expanded", "onHeaderClicked"]))
                    }), 128))
                  ]),
                  _: 1
                }),
                (showButton.value)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      class: "ocm-body2 p-4 underline",
                      onClick: showMore
                    }, _toDisplayString(_ctx.$t("helpPage.showMore")), 1))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
    ]),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("helpPage.nothingSuitable")), 1),
    _createVNode(SharedButton, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => {
				_unref(trackContinueToFormClicked)();
				_unref(startContactForm)(_unref(currentCollection)?.id);
			})
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("helpPage.cta")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_i18n_t, {
      tag: "p",
      class: "mt-4",
      keypath: "helpPage.ctaAlternative"
    }, {
      default: _withCtx(() => [
        _createElementVNode("a", {
          type: "tel",
          href: `tel:${_unref(contactPhoneNumber)()}`,
          class: "font-medium"
        }, [
          _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(_toDisplayString(_unref(contactPhoneNumber)()), 1)
        ], 8, _hoisted_5)
      ]),
      _: 1
    })
  ]))
}
}

})