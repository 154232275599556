<template>
	<component
		:is="isLink ? (to ? 'RouterLink' : 'a') : 'button'"
		class="shared-button inline-flex select-none items-center justify-center whitespace-nowrap rounded-full border align-top text-sm"
		:class="{
			'is-text border-transparent font-medium text-black-primary disabled:text-gray-50': isText,

			'is-primary font-medium text-white disabled:text-gray-50': isPrimary && !dark,
			'is-primary-dark font-semibold text-black-primary focus-visible:outline-white disabled:text-gray-50':
				isPrimary && dark,

			'is-secondary font-semibold text-black-primary backdrop-blur-sm disabled:border-gray-30 disabled:text-gray-50':
				isSecondary && !dark,
			'is-secondary-dark font-medium backdrop-blur-sm focus-visible:outline-white': isSecondary && dark,

			'is-prominent font-medium text-white focus-visible:outline-red-primary disabled:text-gray-50': isProminent,

			'is-paypal font-medium disabled:text-gray-50': isPayPal,

			'is-disabled': isLoading && $attrs['aria-disabled'],
			'px-8': hasSlot,
			'h-11.5': !small,
			'w-11.5': !hasSlot && !small,
			'h-8 w-8': !hasSlot && small,
		}"
		:to="to"
		:type="!isLink ? type : undefined"
		:disabled="!isLink ? isDisabled : undefined"
		:onclick="isLink && orchestratorLink ? 'window.ocmOrchestrator?.spaNavigateUrl(event)' : undefined"
		style="-webkit-tap-highlight-color: transparent"
		:[dynamicDataTestAttr]="true"
	>
		<SharedSpinner v-if="isLoading" dark />
		<template v-else>
			<SvgIcon
				v-if="prependIcon"
				class="shrink-0"
				:class="[small ? 'h-3.5 w-3.5' : `h-${iconSize} w-${iconSize}`, { 'mr-2': hasSlot || appendIcon }]"
				:icon-name="prependIcon"
			/>
			<slot />
			<SvgIcon
				v-if="appendIcon"
				class="shrink-0"
				:class="[small ? 'h-3.5 w-3.5' : `h-${iconSize} w-${iconSize}`, { 'ml-2': hasSlot }]"
				:icon-name="appendIcon"
			/>
		</template>
	</component>
</template>

<script setup lang="ts">
import { computed, useAttrs, useSlots } from "vue";
import SvgIcon from "./SvgIcon/SvgIcon.vue";
import SharedSpinner from "./SharedSpinner.vue";

const props = withDefaults(
	defineProps<{
		variant?: "primary" | "secondary" | "prominent" | "text" | "payPal";
		dark?: boolean; // for primary and secondary variants
		disabled?: boolean;
		isLoading?: boolean;
		to?: object | string; // RouterLink. Not using RouteLocationRaw for Vue2 backwards compatibility
		orchestratorLink?: boolean; // SPA routing between Micro-Frontends
		type?: "button" | "submit" | "reset";
		prependIcon?: string;
		appendIcon?: string;
		iconSize?: string; // Tailwind CSS size unit, e.g. "4"
		small?: boolean; // icon variant only
	}>(),
	{ variant: "primary", type: "button", iconSize: "5" }
);

const isDisabled = computed(() => props.disabled || (props.isLoading && !useAttrs()["aria-disabled"]));

const hasSlot = computed(() => !!useSlots().default);

const isPrimary = computed(() => props.variant === "primary");
const isSecondary = computed(() => props.variant === "secondary");
const isProminent = computed(() => props.variant === "prominent");
const isPayPal = computed(() => props.variant === "payPal");
const isText = computed(() => props.variant === "text");

const isLink = computed(() => props.to || useAttrs().href);
const dynamicDataTestAttr = computed(() =>
	isLink.value ? "data-test-shared-button-anchor" : "data-test-shared-button"
);
</script>

<style lang="scss">
/**
 * If we used Tailwind's 'supportsHover' utility to prevent the iOS hover style bug,
 * it would add specificity to the selector and therefore beat the ':active' and ':disabled' utilities.
 */
.shared-button {
	&.is-text {
		@media (hover: hover) and (pointer: fine) {
			&:hover {
				background-color: var(--color-gray-40);
			}
		}
		&:active {
			background-color: var(--color-gray-60);
		}
		&:disabled,
		&.is-disabled {
			background-color: transparent;
		}
	}

	&.is-primary {
		background-color: var(--color-black-primary);
		border-color: var(--color-black-primary);
		@media (hover: hover) and (pointer: fine) {
			&:hover {
				background-color: var(--color-gray-90);
				border-color: var(--color-gray-90);
			}
		}
		&:active {
			background-color: var(--color-gray-80);
			border-color: var(--color-gray-80);
		}
		&:disabled,
		&.is-disabled {
			background-color: var(--color-gray-30);
			border-color: var(--color-gray-30);
		}
	}

	&.is-primary-dark {
		background-color: var(--color-white);
		border-color: var(--color-white);
		@media (hover: hover) and (pointer: fine) {
			&:hover {
				background-color: var(--color-gray-40);
				border-color: var(--color-gray-40);
			}
		}
		&:active {
			background-color: var(--color-gray-60);
			border-color: var(--color-gray-60);
		}
		&:disabled,
		&.is-disabled {
			background-color: var(--color-gray-30);
			border-color: var(--color-gray-30);
		}
	}

	&.is-secondary {
		background-color: var(--color-white-o-20);
		@media (hover: hover) and (pointer: fine) {
			&:hover {
				background-color: var(--color-gray-40);
			}
		}
		&:active {
			background-color: var(--color-gray-60);
		}
		&:disabled,
		&.is-disabled {
			background-color: var(--color-white);
		}
	}

	&.is-secondary-dark {
		color: var(--color-white);
		background-color: var(--color-black-primary-o-20);
		@media (hover: hover) and (pointer: fine) {
			&:hover {
				color: var(--color-black-primary);
				background-color: var(--color-white);
				border-color: var(--color-gray-40);
			}
		}
		&:active {
			color: var(--color-black-primary);
			background-color: var(--color-gray-40);
			border-color: var(--color-gray-40);
		}
		&:disabled,
		&.is-disabled {
			color: var(--color-gray-50);
			background-color: transparent;
			border-color: currentColor;
		}
	}

	&.is-prominent {
		background-color: var(--color-red-primary);
		border-color: var(--color-red-primary);
		@media (hover: hover) and (pointer: fine) {
			&:hover {
				background-color: var(--color-red-hover);
				border-color: var(--color-red-hover);
			}
		}
		&:active {
			background-color: var(--color-red-active);
			border-color: var(--color-red-active);
		}
		&:disabled,
		&.is-disabled {
			background-color: var(--color-gray-30);
			border-color: var(--color-gray-30);
		}
	}

	&.is-paypal {
		background-color: var(--color-payPal);
		border-color: var(--color-payPal);
		@media (hover: hover) and (pointer: fine) {
			&:hover {
				background-color: var(--color-payPal-hover);
				border-color: var(--color-payPal-hover);
			}
		}
		&:active {
			background-color: var(--color-payPal-hover);
			border-color: var(--color-payPal-hover);
		}
		&:disabled,
		&.is-disabled {
			background-color: var(--color-gray-30);
			border-color: var(--color-gray-30);
		}
	}
}
</style>
