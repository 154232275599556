<template>
	<div>
		<div class="mb-6 mt-10 flex justify-center py-3">
			<SvgIcon icon-name="send-email" class="h-[60px] w-[60px]" />
		</div>
		<h2 class="my-4 mb-2 text-xl font-semibold">{{ $t("thankYouPage.headline") }}</h2>
		<p class="mb-20">
			{{ $t("thankYouPage.text") }}
		</p>
		<SharedButton :href="`${contextPath}/`" variant="primary" class="mb-4 w-3/4 lg-down:w-full">{{
			$t("thankYouPage.cta")
		}}</SharedButton>
		<SharedButton :to="{ name: SELECTION_PAGE_NAME }" variant="secondary" class="w-3/4 lg-down:w-full">{{
			$t("thankYouPage.ctaSecondary")
		}}</SharedButton>
	</div>
</template>

<script setup lang="ts">
import { contextPath } from "@ocm/services/ocm-object";
import SvgIcon from "@SharedVueComponents/SvgIcon/SvgIcon.vue";
import SharedButton from "@SharedVueComponents/SharedButton.vue";
import { SELECTION_PAGE_NAME } from "@/types/routeName.type";
import { resetInputData } from "@/composables/use-messageData";
import { onMounted } from "vue";
import { areAllStepsCompleted, completedSteps } from "@/composables/use-formValidity";
import { startContactForm } from "@/composables/use-pageNavigation";
import { currentCollection } from "@/composables/use-polly";

onMounted(() => {
	const areNotAllStepsCompleted = !areAllStepsCompleted.value; // get state before resetting the form
	resetContactForm();
	if (areNotAllStepsCompleted) startContactForm(currentCollection.value?.id, true);
});

function resetContactForm(): void {
	completedSteps.value.clear();
	resetInputData();
}
</script>
