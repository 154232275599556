enum AuthLevelType {
	FULL_ACCESS = 900,
	REMEMBER_ME = 500,
	EASY_AUTH = 300,
	COOP = 200,
	ANONYMOUS = 0,
}

export const isRoleFullAccess = (authLevel: AuthLevelType): boolean => authLevel === AuthLevelType.FULL_ACCESS;
export const isRoleRememberMe = (authLevel: AuthLevelType): boolean => authLevel === AuthLevelType.REMEMBER_ME;
export const isRoleEasyAuth = (authLevel: AuthLevelType): boolean => authLevel === AuthLevelType.EASY_AUTH;
export const isRoleCoop = (authLevel: AuthLevelType): boolean => authLevel === AuthLevelType.COOP;
export const isRoleAnonymous = (authLevel: AuthLevelType): boolean => authLevel === AuthLevelType.ANONYMOUS;

export const getClientSideAuthLevel = () => window.recommendationData?.Recommendation?.customerData?.authlevel;
