import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-8" }
const _hoisted_2 = { class: "mb-10 text-gray-70" }
const _hoisted_3 = {
  key: 0,
  class: "text-gray-70"
}

import { ref, onBeforeMount, onMounted } from "vue";
import { VueRecaptcha } from "vue-recaptcha";
import { contextPath } from "@ocm/services/ocm-object";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import HeadlineText from "@/components/HeadlineText.vue";
import BtnLink from "@/components/BtnLink.vue";
import { sendMail, mailErrorText } from "@/composables/use-mail";
import { phone, email, lastName, firstName, salutation, emailDisabled } from "@/composables/use-messageData";
import { useErrorTracking } from "@/composables/use-trackingErrors";
import { getAndAutofillContactData } from "@/composables/use-autofill";
import { useI18n } from "vue-i18n";
import { useRerouteToValidStep } from "@/composables/use-formValidity";
import { getCaptchaData, captchaData } from "@/queries/captcha.config";
import TransitionSection from "@/components/TransitionSection.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PersonalDataPage',
  setup(__props) {

const { t } = useI18n({});
const formRef = ref();

const captchaEl = ref<VueRecaptcha>();
const loading = ref(false);

const hideLoadingSpinner = (): void => {
	loading.value = false;
};
const showLoadingSpinner = (): void => {
	loading.value = true;
};

const captchaIsVerified = ref(false);
const captchaToken = ref();

const submit = async (): Promise<void> => {
	if (captchaData.value?.captchaActive) {
		await captchaEl?.value?.execute();
	}
	if (!captchaData.value?.captchaActive || captchaIsVerified.value) {
		showLoadingSpinner();
		await sendMail(captchaToken.value);
		hideLoadingSpinner();
	}
};

onBeforeMount(async () => {
	await getCaptchaData();
	await getAndAutofillContactData(t);
});

onMounted(() => {
	useRerouteToValidStep(formRef);
});

const verifyCaptcha = async (token: string): Promise<void> => {
	showLoadingSpinner();
	await sendMail(token);
	captchaIsVerified.value = true;
	captchaToken.value = token;
	hideLoadingSpinner();
};

const expiredCaptcha = (): void => {
	mailErrorText.value = "generalError";
	console.log("expired");
};

const errorCaptcha = (): void => {
	mailErrorText.value = "generalError";
	console.log("error captcha");
};

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(TransitionSection, null, {
      default: _withCtx(() => [
        (loading.value)
          ? (_openBlock(), _createBlock(LoadingSpinner, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(HeadlineText, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("personalDataPage.headline")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_FormKit, {
      ref_key: "formRef",
      ref: formRef,
      type: "form",
      "submit-label": _ctx.$t('personalDataPage.sendRequest'),
      config: { validationVisibility: 'submit' },
      errors: [_ctx.$t(_unref(mailErrorText))],
      onSubmit: submit
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_FormKit, {
            modelValue: _unref(salutation),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(salutation) ? (salutation).value = $event : null)),
            type: "ocmChipRadio",
            name: _ctx.$t('personalDataPage.salutation'),
            validation: "required",
            plugins: [_unref(useErrorTracking)().trackingPlugin],
            options: [_ctx.$t('personalDataPage.mr'), _ctx.$t('personalDataPage.mrs')]
          }, null, 8, ["modelValue", "name", "plugins", "options"])
        ]),
        _createVNode(_component_FormKit, {
          modelValue: _unref(firstName),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(firstName) ? (firstName).value = $event : null)),
          type: "ocmTextInput",
          "input-type": "text",
          label: _ctx.$t('personalDataPage.firstName'),
          name: _ctx.$t('personalDataPage.firstName'),
          validation: "required",
          plugins: [_unref(useErrorTracking)().trackingPlugin]
        }, null, 8, ["modelValue", "label", "name", "plugins"]),
        _createVNode(_component_FormKit, {
          modelValue: _unref(lastName),
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(lastName) ? (lastName).value = $event : null)),
          type: "ocmTextInput",
          "input-type": "text",
          label: _ctx.$t('personalDataPage.lastName'),
          name: _ctx.$t('personalDataPage.lastName'),
          validation: "required",
          plugins: [_unref(useErrorTracking)().trackingPlugin]
        }, null, 8, ["modelValue", "label", "name", "plugins"]),
        _createVNode(_component_FormKit, {
          modelValue: _unref(email),
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(email) ? (email).value = $event : null)),
          type: "ocmTextInput",
          "input-type": "email",
          label: _ctx.$t('personalDataPage.email'),
          name: _ctx.$t('personalDataPage.email'),
          validation: "required|email",
          plugins: [_unref(useErrorTracking)().trackingPlugin],
          disabled: _unref(emailDisabled)
        }, null, 8, ["modelValue", "label", "name", "plugins", "disabled"]),
        _createVNode(_component_FormKit, {
          modelValue: _unref(phone),
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(phone) ? (phone).value = $event : null)),
          type: "ocmTextInput",
          "input-type": "tel",
          label: _ctx.$t('personalDataPage.phone'),
          name: _ctx.$t('personalDataPage.phone'),
          plugins: [_unref(useErrorTracking)().trackingPlugin]
        }, null, 8, ["modelValue", "label", "name", "plugins"]),
        (_unref(captchaData)?.captchaActive)
          ? (_openBlock(), _createBlock(_unref(VueRecaptcha), {
              key: 0,
              ref_key: "captchaEl",
              ref: captchaEl,
              sitekey: _unref(captchaData).publicKey,
              "load-recaptcha-script": false,
              size: "invisible",
              onVerify: verifyCaptcha,
              onExpired: expiredCaptcha,
              onError: errorCaptcha
            }, null, 8, ["sitekey"]))
          : _createCommentVNode("", true),
        _createElementVNode("p", _hoisted_2, [
          _createVNode(_component_i18n_t, {
            tag: "p",
            class: "mt-4",
            keypath: "personalDataPage.dataText"
          }, {
            default: _withCtx(() => [
              _createVNode(BtnLink, {
                class: "text-gray-70",
                target: "_blank",
                href: `${_unref(contextPath)}/${_ctx.$t('personalDataPage.dataLink')}/`
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("personalDataPage.dataTextPolicy")), 1)
                ]),
                _: 1
              }, 8, ["href"]),
              _cache[5] || (_cache[5] = _createTextVNode(". "))
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["submit-label", "errors"]),
    (_unref(captchaData)?.captchaActive)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("personalDataPage.captchaText")), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})