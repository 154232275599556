<template>
	<div class="mb-8">
		<div class="mt-4">
			<form
				class="flex h-10 cursor-text items-center rounded-full bg-gray-20 px-1 text-gray-80 focus-within:bg-gray-40 focus-within:text-black hover:bg-gray-40 px360:px-5"
				@submit.prevent="getSearchRequest(searchInput)"
			>
				<button
					type="button"
					class="mr-1 grid h-10 w-10 shrink-0 place-content-center"
					:disabled="fetching"
					data-test-name="data-test-help-search-magnifier"
					@click="getSearchRequest(searchInput)"
				>
					<SvgIcon
						:class="{ 'text-error': error }"
						icon-name="magnifying-glass"
						class="h-5 w-5"
						title="Suche abschicken"
					/>
				</button>

				<label class="sr-only" for="searchInput">{{ $t("search.searchHelpArea") }}</label>
				<input
					id="searchInput"
					v-model.trim="searchInput"
					class="h-11 w-1/2 flex-1 appearance-none border-0 bg-transparent text-base leading-5 placeholder:text-gray-80 px360:w-auto"
					type="search"
					:placeholder="$t('search.searchHelpArea')"
					data-test-name="data-test-help-search-input"
					:disabled="fetching"
					enterkeyhint="search"
				/>

				<button
					v-if="searchInput || resultResponse"
					type="button"
					class="ml-2 grid h-10 w-10 shrink-0 place-content-center"
					:disabled="fetching"
					data-test-name="data-test-help-search-button"
					@click="handleDeleteButtonClicked()"
				>
					<SvgIcon :class="{ 'text-error': error }" icon-name="close" class="h-4 w-4" title="Text löschen" />
				</button>
			</form>
			<p v-if="error" class="mt-1 text-sm text-error">
				{{ errorMessage }}
			</p>
		</div>

		<div aria-live="polite" class="mt-4">
			<span v-show="resultResponse">
				{{ $t("search.searchResults", resultResponse?.length || 0) }}
				’{{ lastSearchTerm }}’
			</span>
		</div>

		<ol v-if="resultResponse?.length" class="mt-6">
			<li
				v-for="result in resultResponse"
				:key="result.id"
				class="focus-within:bg-gray-40 hover:bg-gray-40 active:bg-gray-40"
			>
				<hr class="border-t border-gray-30 font-semibold" />
				<routerLink
					:to="{ name: HELP_PAGE_NAME, params: { collectionId: result.collections[0].id }, query: { faq: result.id } }"
					class="block p-4"
					@click="trackSearchResultClicked(searchInput, result.name)"
				>
					{{ result.name }}
					<div class="truncate font-normal">A: {{ result.contentsnippet }}</div>
				</routerLink>
			</li>
		</ol>
		<div v-else-if="resultResponse !== undefined" class="mb-10 mt-6">
			<div class="mb-8 flex justify-center">
				<SvgIcon icon-name="magnifier-error" class="h-15 w-15" />
			</div>
			<p class="mb-2 font-bold">{{ $t("search.noResults") }}</p>
			<p>{{ $t("search.noResultsHint") }}</p>
		</div>
	</div>
</template>

<script setup lang="ts">
import { getSearchRequest, fetching, error, lastSearchTerm } from "@/queries/search.query";
import { searchInput, clearSearch } from "@/composables/use-search";
import { resultResponse } from "@/queries/search.query";
import SvgIcon from "@SharedVueComponents/SvgIcon/SvgIcon.vue";
import { trackSearchResultClicked } from "@/composables/use-trackingInteractions";
import { useI18n } from "vue-i18n";
import { computed, onMounted, watch } from "vue";
import { HELP_PAGE_NAME, SELECTION_PAGE_NAME } from "@/types/routeName.type";
import { LocationQueryValue } from "vue-router";
import router from "@/router";

const { t } = useI18n();

const props = defineProps<{
	searchParam: LocationQueryValue | undefined;
}>();

const errorMessage = computed(() => {
	if (error.value === "missing_characters_error") {
		return t("search.missingCharactersError");
	}
	return t("search.genericSearchError");
});

watch(
	() => props.searchParam,
	() => {
		updateSearchToSearchParam();
	}
);

onMounted(() => {
	updateSearchToSearchParam();
});

function handleDeleteButtonClicked(): void {
	clearSearch();
	router.push({ name: SELECTION_PAGE_NAME });
}

function updateSearchToSearchParam(): void {
	const { searchParam } = props;
	if (searchInput.value === searchParam)
		return; // e.g. browser back after search result click
	else if (searchParam) {
		searchInput.value = searchParam;
		getSearchRequest(searchParam);
	} else {
		clearSearch();
	}
}
</script>
