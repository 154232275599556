import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { goToNextFormStep } from "@/composables/use-pageNavigation";
import { description } from "@/composables/use-messageData";
import { useErrorTracking } from "@/composables/use-trackingErrors";
import HeadlineText from "@/components/HeadlineText.vue";
import { onBeforeMount, onMounted, ref } from "vue";
import { getAndAutofillContactData } from "@/composables/use-autofill";
import { useI18n } from "vue-i18n";
import { useRerouteToValidStep } from "@/composables/use-formValidity";

export default /*@__PURE__*/_defineComponent({
  __name: 'DescriptionPage',
  setup(__props) {

const { t } = useI18n({});
const formRef = ref();

onMounted(() => {
	useRerouteToValidStep(formRef);
});

onBeforeMount(async () => {
	// additional early call to pre-fetch contact data
	await getAndAutofillContactData(t);
});

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(HeadlineText, null, {
      sub: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("descriptionPage.help")), 1)
      ]),
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("descriptionPage.headline")) + " ", 1)
      ]),
      _: 1
    }),
    _createVNode(_component_FormKit, {
      ref_key: "formRef",
      ref: formRef,
      type: "form",
      "submit-label": _ctx.$t('next'),
      config: { validationVisibility: 'submit' },
      onSubmit: _cache[1] || (_cache[1] = ($event: any) => (_unref(goToNextFormStep)()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FormKit, {
          modelValue: _unref(description),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(description) ? (description).value = $event : null)),
          type: "textarea",
          "outer-class": "$reset mb-20",
          validation: "required",
          placeholder: _ctx.$t('descriptionPage.placeholder'),
          plugins: [_unref(useErrorTracking)().trackingPlugin],
          name: _ctx.$t('descriptionPage.description')
        }, null, 8, ["modelValue", "placeholder", "plugins", "name"])
      ]),
      _: 1
    }, 8, ["submit-label"])
  ]))
}
}

})