<template>
	<div>
		<h2 class="my-4 text-xl font-semibold">{{ $t("LoginInfoPage.headline") }}</h2>
		<p class="mb-20">{{ $t("LoginInfoPage.helpText") }}</p>
		<SharedButton primary class="mb-4 w-3/4 lg-down:w-full" @click="triggerReauthOrLogin">{{
			$t("LoginInfoPage.cta")
		}}</SharedButton>
	</div>
</template>

<script setup lang="ts">
import { startContactForm } from "@/composables/use-pageNavigation";
import { redirectLocalStorage, triggerReauthOrLogin } from "@/composables/use-redirect";
import router from "@/router";
import SharedButton from "@SharedVueComponents/SharedButton.vue";
import { onBeforeMount } from "vue";
import { hasUserPermission } from "@/composables/use-restrictions";

onBeforeMount(async () => {
	await redirectLocalStorage();
	redirectToFormIfPermissionsAreValid();
});

async function redirectToFormIfPermissionsAreValid(): Promise<void> {
	const collectionIdParams = router.currentRoute.value.params.collectionId;
	const isCollectionObject = typeof collectionIdParams === "object";
	const collectionIdParam = isCollectionObject ? collectionIdParams[0] : collectionIdParams;
	if (await hasUserPermission()) startContactForm(collectionIdParam, true);
}
</script>
