import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, withCtx as _withCtx } from "vue"

import { computed, useAttrs, useSlots } from "vue";
import SvgIcon from "./SvgIcon/SvgIcon.vue";
import SharedSpinner from "./SharedSpinner.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SharedButton',
  props: {
    variant: { default: "primary" },
    dark: { type: Boolean },
    disabled: { type: Boolean },
    isLoading: { type: Boolean },
    to: {},
    orchestratorLink: { type: Boolean },
    type: { default: "button" },
    prependIcon: {},
    appendIcon: {},
    iconSize: { default: "5" },
    small: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const isDisabled = computed(() => props.disabled || (props.isLoading && !useAttrs()["aria-disabled"]));

const hasSlot = computed(() => !!useSlots().default);

const isPrimary = computed(() => props.variant === "primary");
const isSecondary = computed(() => props.variant === "secondary");
const isProminent = computed(() => props.variant === "prominent");
const isPayPal = computed(() => props.variant === "payPal");
const isText = computed(() => props.variant === "text");

const isLink = computed(() => props.to || useAttrs().href);
const dynamicDataTestAttr = computed(() =>
	isLink.value ? "data-test-shared-button-anchor" : "data-test-shared-button"
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(isLink.value ? (_ctx.to ? 'RouterLink' : 'a') : 'button'), _normalizeProps({
    class: ["shared-button inline-flex select-none items-center justify-center whitespace-nowrap rounded-full border align-top text-sm", {
			'is-text border-transparent font-medium text-black-primary disabled:text-gray-50': isText.value,

			'is-primary font-medium text-white disabled:text-gray-50': isPrimary.value && !_ctx.dark,
			'is-primary-dark font-semibold text-black-primary focus-visible:outline-white disabled:text-gray-50':
				isPrimary.value && _ctx.dark,

			'is-secondary font-semibold text-black-primary backdrop-blur-sm disabled:border-gray-30 disabled:text-gray-50':
				isSecondary.value && !_ctx.dark,
			'is-secondary-dark font-medium backdrop-blur-sm focus-visible:outline-white': isSecondary.value && _ctx.dark,

			'is-prominent font-medium text-white focus-visible:outline-red-primary disabled:text-gray-50': isProminent.value,

			'is-paypal font-medium disabled:text-gray-50': isPayPal.value,

			'is-disabled': _ctx.isLoading && _ctx.$attrs['aria-disabled'],
			'px-8': hasSlot.value,
			'h-11.5': !_ctx.small,
			'w-11.5': !hasSlot.value && !_ctx.small,
			'h-8 w-8': !hasSlot.value && _ctx.small,
		}],
    to: _ctx.to,
    type: !isLink.value ? _ctx.type : undefined,
    disabled: !isLink.value ? isDisabled.value : undefined,
    onclick: isLink.value && _ctx.orchestratorLink ? 'window.ocmOrchestrator?.spaNavigateUrl(event)' : undefined,
    style: {"-webkit-tap-highlight-color":"transparent"},
    [dynamicDataTestAttr.value || ""]: true
  }), {
    default: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(SharedSpinner, {
            key: 0,
            dark: ""
          }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.prependIcon)
              ? (_openBlock(), _createBlock(SvgIcon, {
                  key: 0,
                  class: _normalizeClass(["shrink-0", [_ctx.small ? 'h-3.5 w-3.5' : `h-${_ctx.iconSize} w-${_ctx.iconSize}`, { 'mr-2': hasSlot.value || _ctx.appendIcon }]]),
                  "icon-name": _ctx.prependIcon
                }, null, 8, ["class", "icon-name"]))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "default"),
            (_ctx.appendIcon)
              ? (_openBlock(), _createBlock(SvgIcon, {
                  key: 1,
                  class: _normalizeClass(["shrink-0", [_ctx.small ? 'h-3.5 w-3.5' : `h-${_ctx.iconSize} w-${_ctx.iconSize}`, { 'ml-2': hasSlot.value }]]),
                  "icon-name": _ctx.appendIcon
                }, null, 8, ["class", "icon-name"]))
              : _createCommentVNode("", true)
          ], 64))
    ]),
    _: 3
  }, 16, ["class", "to", "type", "disabled", "onclick"]))
}
}

})