import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inline-grid w-fit" }

import { ref } from "vue";
import SvgIcon from "../SvgIcon/SvgIcon.vue";
import AccessibleModal from "./AccessibleModal.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InfoModal',
  props: {
    headline: {},
    narrow: { type: Boolean },
    iconSize: { default: "4.5" },
    iconLabel: {},
    isVue2: { type: Boolean }
  },
  emits: ["opening"],
  setup(__props: any, { emit: __emit }) {


const emit = __emit;

const dialogEl = ref<InstanceType<typeof AccessibleModal> | null>(null);
const openModal = () => {
	dialogEl.value?.open();
	emit("opening");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      type: "button",
      class: "font-medium underline",
      onClick: openModal
    }, [
      _renderSlot(_ctx.$slots, "trigger", {}, () => [
        _createVNode(SvgIcon, {
          "icon-name": "info",
          class: _normalizeClass(`h-${_ctx.iconSize} w-${_ctx.iconSize}`),
          title: _ctx.iconLabel
        }, null, 8, ["class", "title"])
      ])
    ]),
    _createVNode(AccessibleModal, {
      ref_key: "dialogEl",
      ref: dialogEl,
      headline: _ctx.headline,
      narrow: _ctx.narrow,
      "is-vue2": _ctx.isVue2
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["headline", "narrow", "is-vue2"])
  ]))
}
}

})