import { currentFormFlow } from "@/composables/use-polly";
import router from "@/router";
import { SELECTION_PAGE_NAME } from "@/types/routeName.type";

export function getCurrentInputStepIndex(): number {
	const currentStepName = router.currentRoute.value.name;
	return currentFormFlow.value.findIndex((pageName) => pageName === currentStepName);
}

export function isCurrentPage(pageName: string): boolean {
	const routeName = router.currentRoute.value.name;
	return routeName === pageName;
}

export const startContactForm = async (collectionId: string | undefined, replaceSourceRoute = false): Promise<void> => {
	if (replaceSourceRoute) {
		if (collectionId) router.replace({ name: currentFormFlow.value[0], params: { collectionId } });
		else router.replace({ name: SELECTION_PAGE_NAME });
	} else {
		if (collectionId) router.push({ name: currentFormFlow.value[0], params: { collectionId } });
		else router.push({ name: SELECTION_PAGE_NAME });
	}
};

export const goToNextFormStep = async (): Promise<void> => {
	const currentStepIndex = getCurrentInputStepIndex();
	const nextStepName = currentFormFlow.value[currentStepIndex + 1];
	if (nextStepName) router.push({ name: nextStepName });
};
