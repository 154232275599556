import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "tw" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "peer relative hidden text-base leading-5 em64:absolute em64:top-0 em64:block em64:w-full" }
const _hoisted_4 = ["disabled", "tabindex", "aria-hidden"]
const _hoisted_5 = ["data-key", "onClick"]

import { ref, computed, onMounted, nextTick } from "vue";
import SvgIcon from "@SharedVueComponents/SvgIcon/SvgIcon.vue";
import { useBreakpoints, onClickOutside, onKeyStroke } from "@vueuse/core";
import type { FormKitPlugin } from "@formkit/core";


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseDropdown',
  props: {
    disabled: { type: Boolean },
    icons: {},
    label: {},
    modelValue: {},
    name: {},
    options: {},
    placeholder: {},
    plugins: {},
    validation: {},
    validationVisibility: {},
    validationMessages: {},
    value: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const isEm64 = useBreakpoints({ em64: "64em" }).greaterOrEqual("em64");
const isOpen = ref(false);
const selectedOption = props.value
	? ref(props.value)
	: computed({
			get() {
				return props.modelValue || "";
			},
			set(val: string) {
				emit("update:modelValue", val);
			},
		});

const selectOption = (key: string | number) => {
	selectedOption.value = key.toString();
	isOpen.value = false;
};

const selectedIndex = computed(() => Object.keys(props.options).findIndex((key) => key === selectedOption.value));

const dropdownRef = ref<HTMLDivElement | null>(null);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formkitSelectRef = ref<(HTMLElement & { node: any }) | null>(null);
const optionsListRef = ref<HTMLUListElement | null>(null);
const isInvalid = ref(false);

onClickOutside(dropdownRef, () => (isOpen.value = false));

onMounted(() => {
	formkitSelectRef.value?.node.on("message-added", async () => {
		await nextTick();
		const formKitState = formkitSelectRef.value?.node.context.state;
		isInvalid.value = !formKitState.valid && formKitState.validationVisible;
	});
});

const getFocusedIndex = () =>
	Object.keys(props.options).findIndex((key) => key === document.activeElement?.getAttribute("data-key"));

const focusOption = (index?: number) => {
	if (!optionsListRef.value) return;
	if (index) optionsListRef.value.querySelectorAll("button")[index].focus();
};
// eslint-disable-next-line complexity
const handleKeyUpDown = (e: KeyboardEvent, key: "up" | "down"): void => {
	if (isOpen.value) e.preventDefault();
	const focusedIndex = getFocusedIndex();
	const isFirstOrNoneFocused = focusedIndex < 1;
	const isLastFocused = focusedIndex >= Object.keys(props.options).length - 1;
	if (key === "up" && isFirstOrNoneFocused) return;
	if (key === "down" && isLastFocused) return;
	if (!isOpen.value || !isEm64.value) return;
	focusOption(key === "up" ? focusedIndex - 1 : focusedIndex + 1);
};

onKeyStroke("ArrowUp", (e: KeyboardEvent) => handleKeyUpDown(e, "up"));
onKeyStroke("ArrowDown", (e: KeyboardEvent) => handleKeyUpDown(e, "down"));
onKeyStroke("Escape", () => (isOpen.value = false));

const toggleOpen = () => {
	isOpen.value = !isOpen.value;
	if (isOpen.value && selectedIndex.value > -1) nextTick(() => focusOption(selectedIndex.value));
};

const toClassString = (classObj: { [key: string]: boolean }) =>
	Object.entries(classObj)
		.reduce((classStr, [classes, condition]) => {
			if (!condition) return classStr;
			return `${classStr} ${classes}`;
		}, "")
		.trim();

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.name,
          class: "mb-4 block text-sm font-semibold"
        }, _toDisplayString(_ctx.label), 9, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      ref_key: "dropdownRef",
      ref: dropdownRef,
      class: _normalizeClass(["relative", {
				'text-gray-50': _ctx.disabled,
				'text-black': isOpen.value || (!!_unref(selectedOption) && !_ctx.disabled),
				'text-gray-80': !_unref(selectedOption) && !isOpen.value && !_ctx.disabled,
				'focus-within:text-black hover:text-black': !_ctx.disabled,
			}])
    }, [
      _createVNode(_component_FormKit, {
        id: _ctx.name,
        ref_key: "formkitSelectRef",
        ref: formkitSelectRef,
        modelValue: _unref(selectedOption),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(selectedOption) ? (selectedOption).value = $event : null)),
        disabled: _ctx.disabled,
        name: _ctx.name,
        options: _ctx.options,
        type: "select",
        placeholder: _ctx.placeholder,
        plugins: _ctx.plugins,
        validation: _ctx.validation,
        "validation-visibility": _ctx.validationVisibility,
        "validation-messages": _ctx.validationMessages,
        tabindex: _unref(isEm64) ? '-1' : undefined,
        "aria-hidden": _unref(isEm64) ? 'true' : undefined,
        "input-class": 
					toClassString({
						'bg-arrowDownError border-error focus:shadow-insetBottomError': isInvalid.value,
						'focus:shadow-insetBottomBlack focus:bg-arrowDownBlack': !isInvalid.value,
						'bg-arrowDownGray80 hover:bg-arrowDownBlack border-gray-80': !isInvalid.value && !isOpen.value && !_unref(selectedOption),
						'bg-arrowDownBlack': !isInvalid.value && (isOpen.value || !!_unref(selectedOption)),
						'shadow-insetBottomError': isInvalid.value && isOpen.value,
						'pt-[1rem]': !!_unref(selectedOption) && !!_ctx.placeholder,
					})
				
      }, null, 8, ["id", "modelValue", "disabled", "name", "options", "placeholder", "plugins", "validation", "validation-visibility", "validation-messages", "tabindex", "aria-hidden", "input-class"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          disabled: _ctx.disabled,
          class: _normalizeClass(["relative flex h-15 w-full select-none items-center rounded-t-lg border-b px-4 outline-none focus:text-black disabled:text-gray-50", {
						'border-error focus:shadow-insetBottomError': isInvalid.value,
						'focus:shadow-insetBottomBlack': !isInvalid.value,
						'shadow-insetBottomError': isOpen.value && isInvalid.value,
						'pointer-events-auto cursor-not-allowed border-gray-50': _ctx.disabled,
						'hover:bg-gray-40': !_ctx.disabled,
						'border-gray-80 hover:border-black hover:bg-gray-40': !isInvalid.value && !_ctx.disabled,
						'shadow-insetBottomBlack': isOpen.value && !isInvalid.value,
						'bg-gray-40': isOpen.value,
						'bg-gray-20': !isOpen.value,
					}]),
          type: "button",
          tabindex: _unref(isEm64) ? '0' : '-1',
          "aria-hidden": _unref(isEm64) ? undefined : 'true',
          onClick: _cache[1] || (_cache[1] = ($event: any) => (toggleOpen()))
        }, [
          (_ctx.icons && _ctx.icons[selectedIndex.value])
            ? (_openBlock(), _createBlock(SvgIcon, {
                key: 0,
                class: _normalizeClass(["mr-2 h-5 w-5 shrink-0", { 'translate-y-[0.5rem]': _ctx.placeholder }]),
                "icon-name": _ctx.icons[selectedIndex.value]
              }, null, 8, ["class", "icon-name"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(["mr-1 flex-1 overflow-hidden whitespace-nowrap text-left", { 'pt-[1rem]': _ctx.placeholder }])
          }, _toDisplayString(_unref(selectedOption) ? _ctx.options[_unref(selectedOption)] : ""), 3),
          _createVNode(SvgIcon, {
            class: _normalizeClass(["h-5 w-5 shrink-0 transition-transform", { 'rotate-180': isOpen.value, 'text-error': isInvalid.value }]),
            "icon-name": "arrow-down"
          }, null, 8, ["class"])
        ], 10, _hoisted_4),
        _createElementVNode("ul", {
          ref_key: "optionsListRef",
          ref: optionsListRef,
          class: _normalizeClass(["absolute left-0 top-full z-10 w-full text-black-primary shadow-4", { hidden: !isOpen.value }]),
          style: {"clip-path":"inset(0 -20px -20px -20px)"}
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, key, i) => {
            return (_openBlock(), _createElementBlock("li", { key: key }, [
              _createElementVNode("button", {
                class: "flex h-12 w-full select-none items-center bg-white px-4 outline-none hover:bg-gray-40 hover:text-black focus:bg-gray-40 focus:text-black",
                "data-key": key,
                type: "button",
                onClick: ($event: any) => (selectOption(key))
              }, [
                (_ctx.icons && _ctx.icons[i])
                  ? (_openBlock(), _createBlock(SvgIcon, {
                      key: 0,
                      class: "mr-2 h-5 w-5 shrink-0",
                      "icon-name": _ctx.icons[i]
                    }, null, 8, ["icon-name"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: _normalizeClass(["flex-1 text-left", { 'ml-7': _ctx.icons && !_ctx.icons[i] }])
                }, _toDisplayString(option), 3)
              ], 8, _hoisted_5)
            ]))
          }), 128))
        ], 2)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["pointer-events-none absolute left-4 top-0 flex h-15 origin-left select-none items-center transition-[opacity,_transform]", [
					{
						'text-error': isInvalid.value,
						'peer-hover:text-black': !isInvalid.value && !_ctx.disabled,
						'text-gray-80': !isInvalid.value && _unref(selectedOption) && !isOpen.value,
					},
					isOpen.value || _unref(selectedOption)
						? '-translate-y-[0.928571428571429rem] scale-75 opacity-100'
						: 'opacity-0 em64:opacity-100',
				]])
      }, _toDisplayString(_ctx.placeholder), 3)
    ], 2)
  ]))
}
}

})