import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, vShow as _vShow, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mb-8" }
const _hoisted_2 = { class: "mt-4" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = {
  class: "sr-only",
  for: "searchInput"
}
const _hoisted_5 = ["placeholder", "disabled"]
const _hoisted_6 = ["disabled"]
const _hoisted_7 = {
  key: 0,
  class: "mt-1 text-sm text-error"
}
const _hoisted_8 = {
  "aria-live": "polite",
  class: "mt-4"
}
const _hoisted_9 = {
  key: 0,
  class: "mt-6"
}
const _hoisted_10 = { class: "truncate font-normal" }
const _hoisted_11 = {
  key: 1,
  class: "mb-10 mt-6"
}
const _hoisted_12 = { class: "mb-8 flex justify-center" }
const _hoisted_13 = { class: "mb-2 font-bold" }

import { getSearchRequest, fetching, error, lastSearchTerm } from "@/queries/search.query";
import { searchInput, clearSearch } from "@/composables/use-search";
import { resultResponse } from "@/queries/search.query";
import SvgIcon from "@SharedVueComponents/SvgIcon/SvgIcon.vue";
import { trackSearchResultClicked } from "@/composables/use-trackingInteractions";
import { useI18n } from "vue-i18n";
import { computed, onMounted, watch } from "vue";
import { HELP_PAGE_NAME, SELECTION_PAGE_NAME } from "@/types/routeName.type";
import { LocationQueryValue } from "vue-router";
import router from "@/router";


export default /*@__PURE__*/_defineComponent({
  __name: 'FaqSearch',
  props: {
    searchParam: {}
  },
  setup(__props: any) {

const { t } = useI18n();

const props = __props;

const errorMessage = computed(() => {
	if (error.value === "missing_characters_error") {
		return t("search.missingCharactersError");
	}
	return t("search.genericSearchError");
});

watch(
	() => props.searchParam,
	() => {
		updateSearchToSearchParam();
	}
);

onMounted(() => {
	updateSearchToSearchParam();
});

function handleDeleteButtonClicked(): void {
	clearSearch();
	router.push({ name: SELECTION_PAGE_NAME });
}

function updateSearchToSearchParam(): void {
	const { searchParam } = props;
	if (searchInput.value === searchParam)
		return; // e.g. browser back after search result click
	else if (searchParam) {
		searchInput.value = searchParam;
		getSearchRequest(searchParam);
	} else {
		clearSearch();
	}
}

return (_ctx: any,_cache: any) => {
  const _component_routerLink = _resolveComponent("routerLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        class: "flex h-10 cursor-text items-center rounded-full bg-gray-20 px-1 text-gray-80 focus-within:bg-gray-40 focus-within:text-black hover:bg-gray-40 px360:px-5",
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_unref(getSearchRequest)(_unref(searchInput))), ["prevent"]))
      }, [
        _createElementVNode("button", {
          type: "button",
          class: "mr-1 grid h-10 w-10 shrink-0 place-content-center",
          disabled: _unref(fetching),
          "data-test-name": "data-test-help-search-magnifier",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(getSearchRequest)(_unref(searchInput))))
        }, [
          _createVNode(SvgIcon, {
            class: _normalizeClass([{ 'text-error': _unref(error) }, "h-5 w-5"]),
            "icon-name": "magnifying-glass",
            title: "Suche abschicken"
          }, null, 8, ["class"])
        ], 8, _hoisted_3),
        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("search.searchHelpArea")), 1),
        _withDirectives(_createElementVNode("input", {
          id: "searchInput",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(searchInput) ? (searchInput).value = $event : null)),
          class: "h-11 w-1/2 flex-1 appearance-none border-0 bg-transparent text-base leading-5 placeholder:text-gray-80 px360:w-auto",
          type: "search",
          placeholder: _ctx.$t('search.searchHelpArea'),
          "data-test-name": "data-test-help-search-input",
          disabled: _unref(fetching),
          enterkeyhint: "search"
        }, null, 8, _hoisted_5), [
          [
            _vModelText,
            _unref(searchInput),
            void 0,
            { trim: true }
          ]
        ]),
        (_unref(searchInput) || _unref(resultResponse))
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              class: "ml-2 grid h-10 w-10 shrink-0 place-content-center",
              disabled: _unref(fetching),
              "data-test-name": "data-test-help-search-button",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (handleDeleteButtonClicked()))
            }, [
              _createVNode(SvgIcon, {
                class: _normalizeClass([{ 'text-error': _unref(error) }, "h-4 w-4"]),
                "icon-name": "close",
                title: "Text löschen"
              }, null, 8, ["class"])
            ], 8, _hoisted_6))
          : _createCommentVNode("", true)
      ], 32),
      (_unref(error))
        ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(errorMessage.value), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.$t("search.searchResults", _unref(resultResponse)?.length || 0)) + " ’" + _toDisplayString(_unref(lastSearchTerm)) + "’ ", 513), [
        [_vShow, _unref(resultResponse)]
      ])
    ]),
    (_unref(resultResponse)?.length)
      ? (_openBlock(), _createElementBlock("ol", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(resultResponse), (result) => {
            return (_openBlock(), _createElementBlock("li", {
              key: result.id,
              class: "focus-within:bg-gray-40 hover:bg-gray-40 active:bg-gray-40"
            }, [
              _cache[4] || (_cache[4] = _createElementVNode("hr", { class: "border-t border-gray-30 font-semibold" }, null, -1)),
              _createVNode(_component_routerLink, {
                to: { name: _unref(HELP_PAGE_NAME), params: { collectionId: result.collections[0].id }, query: { faq: result.id } },
                class: "block p-4",
                onClick: ($event: any) => (_unref(trackSearchResultClicked)(_unref(searchInput), result.name))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(result.name) + " ", 1),
                  _createElementVNode("div", _hoisted_10, "A: " + _toDisplayString(result.contentsnippet), 1)
                ]),
                _: 2
              }, 1032, ["to", "onClick"])
            ]))
          }), 128))
        ]))
      : (_unref(resultResponse) !== undefined)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(SvgIcon, {
                "icon-name": "magnifier-error",
                class: "h-15 w-15"
              })
            ]),
            _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t("search.noResults")), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t("search.noResultsHint")), 1)
          ]))
        : _createCommentVNode("", true)
  ]))
}
}

})