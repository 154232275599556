import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { ref } from "vue";
import SharedDialog from "../SharedDialog/SharedDialog.vue";
import Vue2Modal from "./Modal.vue";
import Vue3Modal from "./Vue3Modal.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AccessibleModal',
  props: {
    closeActionButton: { type: Boolean },
    closeActionButtonText: {},
    fullMaxHeight: { type: Boolean },
    headline: {},
    narrow: { type: Boolean },
    noBodyPadding: { type: Boolean },
    noScrollbar: { type: Boolean },
    preventDismiss: { type: Boolean },
    wide: { type: Boolean },
    ifShowIsOpen: { type: Boolean, default: undefined },
    isVue2: { type: Boolean },
    zIndex: {}
  },
  emits: ["closing", "closed"],
  setup(__props: any, { expose: __expose, emit: __emit }) {


const emit = __emit;

const isDialogSupported = "showModal" in document.createElement("dialog");

const dialogEl = ref<InstanceType<typeof SharedDialog> | null>(null);
const modalEl = ref<InstanceType<typeof Vue2Modal> | InstanceType<typeof Vue3Modal> | null>(null);
const isOpen = ref(false);

const open = () => {
	isDialogSupported ? dialogEl.value?.open() : (isOpen.value = true);
};
const close = () => {
	(isDialogSupported ? dialogEl.value : modalEl.value)?.close();
};

const onClosing = (triggeredBy: string) => {
	emit("closing", triggeredBy);
};
const onClosed = () => {
	emit("closed");
	if (!isDialogSupported) isOpen.value = false;
};

__expose({ open, close });

return (_ctx: any,_cache: any) => {
  return isDialogSupported
    ? (_openBlock(), _createBlock(SharedDialog, {
        key: 0,
        ref_key: "dialogEl",
        ref: dialogEl,
        "close-action-button": _ctx.closeActionButton,
        "close-action-button-text": _ctx.closeActionButtonText,
        "full-max-height": _ctx.fullMaxHeight,
        headline: _ctx.headline,
        narrow: _ctx.narrow,
        "no-body-padding": _ctx.noBodyPadding,
        "no-scrollbar": _ctx.noScrollbar,
        "prevent-dismiss": _ctx.preventDismiss,
        wide: _ctx.wide,
        onClosing: onClosing,
        onClosed: onClosed
      }, _createSlots({
        footer: _withCtx(() => [
          _renderSlot(_ctx.$slots, "footer")
        ]),
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 2
      }, [
        (_ctx.$slots.headerblock)
          ? {
              name: "headerblock",
              fn: _withCtx(() => [
                _renderSlot(_ctx.$slots, "headerblock")
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["close-action-button", "close-action-button-text", "full-max-height", "headline", "narrow", "no-body-padding", "no-scrollbar", "prevent-dismiss", "wide"]))
    : (_ctx.isVue2 && isOpen.value)
      ? (_openBlock(), _createBlock(Vue2Modal, {
          key: 1,
          ref_key: "modalEl",
          ref: modalEl,
          "close-action-button": _ctx.closeActionButton,
          "close-action-button-text": _ctx.closeActionButtonText,
          "full-max-height": _ctx.fullMaxHeight,
          headline: _ctx.headline,
          narrow: _ctx.narrow,
          "no-body-padding": _ctx.noBodyPadding,
          "no-scrollbar": _ctx.noScrollbar,
          wide: _ctx.wide,
          "z-index": _ctx.zIndex,
          onClosing: onClosing,
          onClosed: onClosed
        }, _createSlots({
          footer: _withCtx(() => [
            _renderSlot(_ctx.$slots, "footer")
          ]),
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 2
        }, [
          (_ctx.$slots.headerblock)
            ? {
                name: "headerblock",
                fn: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "headerblock")
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["close-action-button", "close-action-button-text", "full-max-height", "headline", "narrow", "no-body-padding", "no-scrollbar", "wide", "z-index"]))
      : (_ctx.ifShowIsOpen !== undefined || isOpen.value)
        ? (_openBlock(), _createBlock(Vue3Modal, {
            key: 2,
            ref_key: "modalEl",
            ref: modalEl,
            "close-action-button": _ctx.closeActionButton,
            "close-action-button-text": _ctx.closeActionButtonText,
            "full-max-height": _ctx.fullMaxHeight,
            headline: _ctx.headline,
            "if-show-is-open": _ctx.ifShowIsOpen,
            narrow: _ctx.narrow,
            "no-body-padding": _ctx.noBodyPadding,
            "no-scrollbar": _ctx.noScrollbar,
            "prevent-dismiss": _ctx.preventDismiss,
            wide: _ctx.wide,
            onClosing: onClosing,
            onClosed: onClosed
          }, _createSlots({
            footer: _withCtx(() => [
              _renderSlot(_ctx.$slots, "footer")
            ]),
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 2
          }, [
            (_ctx.$slots.headerblock)
              ? {
                  name: "headerblock",
                  fn: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "headerblock")
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["close-action-button", "close-action-button-text", "full-max-height", "headline", "if-show-is-open", "narrow", "no-body-padding", "no-scrollbar", "prevent-dismiss", "wide"]))
        : _createCommentVNode("", true)
}
}

})