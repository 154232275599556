<template>
	<RouterLink
		:id="collection.id"
		:to="{ name: HELP_PAGE_NAME, params: { collectionId: collection.id } }"
		class="grid place-content-center rounded-lg bg-white p-4 text-center text-sm shadow-contactCards hover:bg-gray-40 focus-visible:bg-gray-40"
		@click.once="setCollectionState(collection)"
	>
		{{ collection.name }}</RouterLink
	>
</template>

<script setup lang="ts">
import { currentCollection } from "@/composables/use-polly";
import { HELP_PAGE_NAME } from "@/types/routeName.type";
import { Collection } from "@/types/publications.type";
import { RouterLink } from "vue-router";

defineProps<{
	collection: Collection;
}>();
const setCollectionState = (collection: Collection) => {
	currentCollection.value = collection;
};
</script>
