<template>
	<dl class="tw border-t border-gray-30">
		<slot />
	</dl>
</template>

<script setup lang="ts">
import { nextTick, provide, ref } from "vue";

const props = defineProps<{
	stayOpen?: boolean;
}>();

const isCollapsing = ref(false);

provide("isCollapsing", isCollapsing);
provide("collapseOthers", () => {
	if (props.stayOpen) return;
	isCollapsing.value = true;
	nextTick(() => (isCollapsing.value = false));
});
</script>
