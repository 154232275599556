import { ref } from "vue";
import { $axios } from "@ocm/axios";

export const data = ref(null);
export const showFeedbackSuccessIds = ref<string[]>([]);
export const feedbackErrorText = ref<string>("");
import { trackFeedbackSubmit } from "@/composables/use-trackingInteractions";

export const sendFeedback = async (
	article: string,
	articleName: string,
	value: number,
	content = "",
	feedbackReason?: number,
	context = ""
) => {
	const url = "rest/contactcenter/feedback";
	const json = JSON.stringify({ context, content, article, value, feedbackReason });
	trackFeedbackSubmit(articleName, Boolean(value));
	try {
		const response = await $axios.post(url, json, {
			headers: { "content-type": "application/json" },
		});
		showFeedbackSuccessIds.value.push(article);
		feedbackErrorText.value = "";
		return response;
	} catch (err) {
		feedbackErrorText.value = "generalError";
		console.error(err);
	}
};
