<template>
	<div class="inline-grid w-fit">
		<button type="button" class="font-medium underline" @click="openModal">
			<slot name="trigger">
				<SvgIcon icon-name="info" :class="`h-${iconSize} w-${iconSize}`" :title="iconLabel" />
			</slot>
		</button>

		<AccessibleModal ref="dialogEl" :headline="headline" :narrow="narrow" :is-vue2="isVue2">
			<slot />
		</AccessibleModal>
	</div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import SvgIcon from "../SvgIcon/SvgIcon.vue";
import AccessibleModal from "./AccessibleModal.vue";

withDefaults(
	defineProps<{
		headline?: string;
		narrow?: boolean;
		iconSize?: string; // Tailwind CSS size unit, e.g. "4"
		iconLabel?: string;
		isVue2?: boolean;
	}>(),
	{ iconSize: "4.5" }
);
const emit = defineEmits<{
	(event: "opening"): void;
}>();

const dialogEl = ref<InstanceType<typeof AccessibleModal> | null>(null);
const openModal = () => {
	dialogEl.value?.open();
	emit("opening");
};
</script>
