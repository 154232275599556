import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { getCurrentInputStepIndex } from "@/composables/use-pageNavigation";
import { searchInput } from "@/composables/use-search";
import { resultResponse } from "@/queries/search.query";
import SvgIcon from "@SharedVueComponents/SvgIcon/SvgIcon.vue";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { HELP_PAGE_NAME, LOGIN_INFO_PAGE_NAME, SELECTION_PAGE_NAME, THANK_YOU_PAGE_NAME } from "@/types/routeName.type";
import { currentCollection, currentFormFlow } from "@/composables/use-polly";
import { publicationData } from "@/queries/publications.query";
import router from "@/router";


export default /*@__PURE__*/_defineComponent({
  __name: 'BackButton',
  setup(__props) {

const isSearchActive = computed(() => resultResponse.value !== undefined);
const route = useRoute();

const isButtonInvisible = computed(
	() =>
		(route.name === SELECTION_PAGE_NAME && !isSearchActive.value) ||
		route.name === THANK_YOU_PAGE_NAME ||
		(!publicationData.value && getCurrentInputStepIndex() === 0)
);

const goToPreviousStep = (): void => {
	const currentFormStepIndex = getCurrentInputStepIndex();
	if (currentFormStepIndex === 0) goExitFormFlow();
	else if (currentFormStepIndex > 0) goBackInFormFlow(currentFormStepIndex);
	else goBackToHardcodedPage();
};

function goExitFormFlow(): void {
	router.push({
		name: HELP_PAGE_NAME,
		params: currentCollection.value ? { collectionId: currentCollection.value.id } : undefined,
		query: { backNavigationNavigation: "true" },
	});
}

function goBackInFormFlow(currentFormStep: number): void {
	router.push({ name: currentFormFlow.value[currentFormStep - 1] });
}

function goBackToHardcodedPage(): void {
	const currentRouteName = router.currentRoute.value.name;
	switch (currentRouteName) {
		case HELP_PAGE_NAME:
			router.push({ name: SELECTION_PAGE_NAME, query: searchInput.value ? { search: searchInput.value } : {} });
			break;
		case LOGIN_INFO_PAGE_NAME:
			router.push({
				name: HELP_PAGE_NAME,
				params: currentCollection.value ? { collectionId: currentCollection.value.id } : undefined,
				query: { backNavigationNavigation: "true" },
			});
			break;
		case SELECTION_PAGE_NAME:
			router.push({ name: SELECTION_PAGE_NAME });
			break;
		default:
			console.error(
				"Go back one step. Because of unexpected browser back/forward behavior this should never take effect"
			);
			router.go(-1);
	}
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["mt-4 flex w-full text-xs font-medium", { invisible: isButtonInvisible.value }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (goToPreviousStep()))
  }, [
    _createVNode(SvgIcon, {
      class: "mr-1 h-4 w-4",
      "icon-name": "arrow-thin-left"
    }),
    _createTextVNode(" " + _toDisplayString(_ctx.$t("back")), 1)
  ], 2))
}
}

})